/* eslint-disable no-underscore-dangle */
export const CUSTOMER_ENTITY = 6;
export const GROUP_ENTITY = 5;
export const VIEW_ENTITY = 7;
export const FOLDER_ENTITY = 4;
export const INVESTMENT_ENTITY = 3;
export const ASSET_ENTITY = 2;
export const LOCATION_ENTITY = 1;

export const SCENARIO_85_ID = 1;
export const SCENARIO_45_ID = 2;
export const SCENARIO_26_ID = 3;
export const SCENARIO_60_ID = 4;

export const ALL_SCENARIO_OPTIONS = [
    { value: SCENARIO_85_ID, name: '8.5', description: '(3.2 - 5.4°C)' },
    { value: SCENARIO_60_ID, name: '6.0', description: '(2.0 - 3.7°C)' },
    { value: SCENARIO_45_ID, name: '4.5', description: '(1.7 - 3.2°C)' },
    { value: SCENARIO_26_ID, name: '2.6', description: '(0.9 - 2.3°C)' },
];

export const TAB_INDEXES = {
    allAssets: 'All Assets-tab',
    folders: 'Folders-tab',
    views: 'Groups-tab',
};

// Mapbox and Mapbox Atlas
export const EMPTY_DATA_SOURCE_JSON = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: [],
    },
}
export const RISKS = 1;
export const OPPORTUNITIES = 2;
export const ALL_RISK_FACTOR_CATEGORIES = [2];
export const UNCATEGORIZED = 'Uncategorized';

export const ALL_RISK_FACTORS = [
    'Carbon Pricing',
    'Litigation',
    'Reputation',
    'Technology',
    'Market',
    'Wildfire',
    'Temperature Extremes',
    'Water Stress',
    'Coastal Flooding',
    'Fluvial Flooding',
    'Tropical Cyclone',
    'Drought',
    'Pluvial Flooding',
    'Landslide',
    'Wind Change - Low Wind Speed',
    'Wind Change - High Wind Speed'
];
export const ALL_RISK_FACTOR_IDS = [
    {
        riskFactorId: 1,
        name: 'Carbon Pricing',
        categoryId: 1,
        dataUnavailableMessage:
            'Not able to compute Carbon Pricing risk because emissions data was not provided.',
    },
    {
        riskFactorId: 4,
        name: 'Reputation',
        categoryId: 1,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 7,
        name: 'Pluvial Flooding',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 8,
        name: 'Temperature Extremes',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 9,
        name: 'Coastal Flooding',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 15,
        name: 'Drought',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 16,
        name: 'Wildfire',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 17,
        name: 'Tropical Cyclone',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 18,
        name: 'Water Stress',
        categoryId: 2,
        dataUnavailableMessage:
            'The necessary supporting data for this asset\'s location is not available, so no risk is estimated for this hazard.',
    },
    {
        riskFactorId: 19,
        name: 'Market',
        categoryId: 1,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 25,
        name: 'Fluvial Flooding',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 2,
        name: 'Litigation',
        categoryId: 1,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 3,
        name: 'Technology',
        categoryId: 1,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
    {
        riskFactorId: 35,
        name: 'Landslide',
        categoryId: 2,
        dataUnavailableMessage: 'Not able to compute hazard data for this location.',
    },
];

export const PHYSICAL_RISK_FACTOR_IDS = [
    { id: 7, label: 'Pluvial Flooding', value: 7 },
    { id: 8, label: 'Temperature Extremes', value: 8 },
    { id: 9, label: 'Coastal Flooding', value: 9 },
    { id: 15, label: 'Drought', value: 15 },
    { id: 16, label: 'Wildfire', value: 16 },
    { id: 17, label: 'Tropical Cyclone', value: 17 },
    { id: 18, label: 'Water Stress', value: 18 },
    { id: 25, label: 'Fluvial Flooding', value: 25 },
    { id: 35, label: 'Landslide', value: 35 },
    { id: 31, label: 'Wind Change - Low Wind Speed', value: 31 },
    { id: 32, label: 'Wind Change - High Wind Speed', value: 32 },
];

export const TRANSITION_RISK_FACTOR_IDS = [
    { id: 1, label: 'Carbon Pricing', value: 1 },
    { id: 2, label: 'Litigation', value: 2 },
    { id: 3, label: 'Technology', value: 3 },
    { id: 4, label: 'Reputation', value: 4 },
    { id: 19, label: 'Market', value: 19 },
];
export const EXPOSURE_HAZARD_IDS = [
    { id: 27, label: 'Extreme Cold', value: 27 },
    { id: 25, label: 'Fluvial Flood', value: 25 },
    { id: 28, label: 'Extreme Heat', value: 28 },
    { id: 17, label: 'Tropical Cyclone', value: 17 },
    { id: 9, label: 'Coastal Flood', value: 9 },
    { id: 18, label: 'Water Stress', value: 18 },
    { id: 16, label: 'Wildfire', value: 16 },
    { id: 15, label: 'Drought', value: 15 },
    { id: 7, label: 'Pluvial Flood', value: 7 },
    { id: 26, label: 'Composite', value: 26 },
];

export const HAZARD_ONLY = [
    { id: 31, name: 'Wind Change - Low Wind Speed' },
    { id: 32, name: 'Wind Change - High Wind Speed' }
];

export const RISK_FACTORS_CATEGORIES_NAME_MAPPING = {
    physical_risk: 'Physical Risks',
    transition_risk: 'Transition Risk',
};

export const ALL_OPPS_IDS = [
    { riskFactorId: 20, name: 'Resource Efficiency', categoryId: 3 },
    { riskFactorId: 21, name: 'Energy Source', categoryId: 3 },
    { riskFactorId: 22, name: 'Products and Services', categoryId: 3 },
    { riskFactorId: 23, name: 'Markets', categoryId: 3 },
    { riskFactorId: 24, name: 'Resilience', categoryId: 3 },
];

export const OUTPUT_OPTIONS = [
    { id: 2, label: 'Physical Risk Financial Impact', value: 'PRFI' },
    { id: 1, label: 'Transition Risk Financial Impact', value: 'TRFI' },
    {
        id: 3,
        label: 'Opportunity Factors Financial Impact',
        value: 'ORFI',
    },
    { id: 4, label: 'Physical Risk Hazard Metrics', value: 'PRHM' },
    { id: 5, label: 'Physical Risk Hazard Metrics - Baseline', value: 'PRHM-B' },
    { id: 6, label: 'Percentage At Risk By Impact Pathway', value: 'PRIP' },
    { id: 101, label: 'Exposure Scores [BETA]', value: 'EXPO' },
];

export const RANGE_OPTIONS = [
    { id: 1, label: 'Last 3 Months', value: '3M' },
    { id: 2, label: 'Last 6 Months', value: '6M' },
    { id: 3, label: 'Last 1 Year', value: '1Y' },
    { id: 4, label: 'Last 3 Years', value: '3Y' },
    { id: 5, label: 'Last 5 Years', value: '5Y' },
    { id: 6, label: 'All', value: 'ALL' },
    { id: 7, label: 'Custom', value: 'CUSTOM' },
];

export const SCENARIO_OPTIONS = [
    { id: 1, label: 'High (RCP 8.5/SSP5-8.5)', value: '4' },
    { id: 4, label: 'Medium-High (RCP 7.0/SSP3-7.0)', value: '3' },
    { id: 2, label: 'Medium (RCP 4.5/SSP2-4.5)', value: '2' },
    { id: 3, label: 'Low (RCP 2.6/SSP1-2.6)', value: '1' },
];

export const DECADE_OPTIONS = [
    { label: '2020', value: '2020' },
    { label: '2030', value: '2030' },
    { label: '2040', value: '2040' },
    { label: '2050', value: '2050' },
    { label: '2060', value: '2060' },
    { label: '2070', value: '2070' },
    { label: '2080', value: '2080' },
    { label: '2090', value: '2090' },
];

export const MULTIPLE_RETURN_PERIOD_OPTIONS = [
    { label: '1 in 1 year', value: 1 },
    { label: '1 in 2 years', value: 2 },
    { label: '1 in 5 years', value: 5 },
    { label: '1 in 10 years', value: 10 },
    { label: '1 in 20 years', value: 20 },
    { label: '1 in 50 years', value: 50 },
    { label: '1 in 100 years', value: 100 },
    { label: '1 in 200 years', value: 200 },
    { label: '1 in 500 years', value: 500 },
    { label: '1 in 1000 years', value: 1000 },
];

export const DATAPOINT_OPTIONS = [
    { label: 'Street Address', value: 'streetAddress' },
    { label: 'City/Town', value: 'city-town' },
    { label: 'State/Province', value: 'state-province' },
    { label: 'Postal Code', value: 'postalCode' },
    { label: 'Country', value: 'country' },
    { label: 'Latitude', value: 'latitude' },
    { label: 'Longitude', value: 'longitude' },
    { label: 'Elevation', value: 'elevation' },
    { label: 'Emissions', value: 'emissions' },
    { label: 'Location Reference ID', value: 'locationReferenceID' },
    { label: 'Folder Name', value: 'folderName' },
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Date Updated', value: 'dateUpdated' },
];

export const DATAPOINT_OPTIONS_DMAAL = [
    { label: 'Street Address', value: 'streetAddress' },
    { label: 'City/Town', value: 'city-town' },
    { label: 'State/Province', value: 'state-province' },
    { label: 'Postal Code', value: 'postalCode' },
    { label: 'Country', value: 'country' },
    { label: 'Latitude', value: 'latitude' },
    { label: 'Longitude', value: 'longitude' },
    { label: 'Elevation', value: 'elevation' },
    { label: 'Emissions', value: 'emissions' },
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Date Updated', value: 'dateUpdated' },
];

export const DATAPOINT_OPTIONS_EXPOSURE = [
    { label: 'Latitude', value: 'latitude' },
    { label: 'Longitude', value: 'longitude' },
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Date Updated', value: 'dateUpdated' },
];

export const ANALYSIS_TYPE_OPTIONS = [
    { label: 'Physical Risk', value: 'P' },
    { label: 'Transition Risk', value: 'T' },
    { label: 'Opportunities', value: 'O' },
];

export const PORTFOLIO_SUMMARY_REPORT = { label: 'Portfolio', value: 'portfolio_summary_report' };
export const ASSET_SUMMARY_REPORT = { label: 'Single Asset', value: 'asset_summary_report' };

export const REPORT_TYPE_OPTIONS = [
    { label: PORTFOLIO_SUMMARY_REPORT.label, value: PORTFOLIO_SUMMARY_REPORT.value },
    { label: ASSET_SUMMARY_REPORT.label, value: ASSET_SUMMARY_REPORT.value },
];

export const SP_EMAIL_DOMAIN = '@spglobal.com';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const STANDARD_IMPORT_HEADERS = [
    'Asset Name',
    'Asset Reference ID',
    'Asset Value',
    'Asset Emissions',
    'Latitude',
    'Longitude',
    'Street Address',
    'City',
    'State / Province',
    'Postal Code',
    'Country',
    'Location Reference ID',
    'Asset Type ID',
    'Asset Folder ID',
    'Tags',
];

export const API_AUDIENCE =
    process.env.NODE_ENV === 'production'
        ? window._env_.REACT_APP_API_AUDIENCE
        : process.env.REACT_APP_API_AUDIENCE || 'localhost:5000';
export const API_URL =
    process.env.NODE_ENV === 'production'
        ? window._env_.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const AUTH_TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_NAME || 'CLIMANOMICS_USER_TOKEN';
export const MOCK_DATA = process.env.REACT_APP_MOCK_DATA;

// export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
// export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
// export const API_AUDIENCE = process.env.REACT_APP_API_AUDIENCE || 'localhost:5000';
// export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const GLOBAL_EDIT_ASSET_TYPE = 'global:edit:asset_type';
export const GLOBAL_READ_ASSET_TYPE = 'global:read:asset_type';
export const CUSTOMER_EDIT_ASSET_TYPE = 'customer:edit:asset_type';
export const CUSTOMER_READ_ASSET_TYPE = 'customer:read:asset_type';
export const GLOBAL_EDIT_ASSET = 'global:edit:asset';
export const GLOBAL_READ_ASSET = 'global:read:asset';
export const CUSTOMER_EDIT_ASSET = 'customer:edit:asset';
export const CUSTOMER_READ_ASSET = 'customer:read:asset';
export const GLOBAL_EDIT_IMPACT_FUNCTION = 'global:edit:impact_function';
export const GLOBAL_READ_IMPACT_FUNCTION = 'global:read:impact_function';
export const CUSTOMER_EDIT_IMPACT_FUNCTION = 'customer:edit:impact_function';
export const CUSTOMER_READ_IMPACT_FUNCTION = 'customer:read:impact_function';
export const GLOBAL_EDIT_INVESTMENT = 'global:edit:investment';
export const GLOBAL_READ_INVESTMENT = 'global:read:investment';
export const CUSTOMER_EDIT_INVESTMENT = 'customer:edit:investment';
export const CUSTOMER_READ_INVESTMENT = 'customer:read:investment';
export const GLOBAL_EDIT_CUSTOMER = 'global:edit:customer';
export const GLOBAL_READ_CUSTOMER = 'global:read:customer';
export const CUSTOMER_EDIT_CUSTOMER = 'customer:edit:customer';
export const CUSTOMER_READ_CUSTOMER = 'customer:read:customer';
export const GLOBAL_EDIT_HIERARCHY = 'global:edit:hierarchy';
export const GLOBAL_READ_HIERARCHY = 'global:read:hierarchy';
export const CUSTOMER_EDIT_HIERARCHY = 'customer:edit:hierarchy';
export const CUSTOMER_READ_HIERARCHY = 'customer:read:hierarchy';

export const ALL_SCOPES = [
    GLOBAL_EDIT_ASSET_TYPE,
    GLOBAL_READ_ASSET_TYPE,
    CUSTOMER_EDIT_ASSET_TYPE,
    CUSTOMER_READ_ASSET_TYPE,
    GLOBAL_EDIT_ASSET,
    GLOBAL_READ_ASSET,
    CUSTOMER_EDIT_ASSET,
    CUSTOMER_READ_ASSET,
    GLOBAL_EDIT_IMPACT_FUNCTION,
    GLOBAL_READ_IMPACT_FUNCTION,
    CUSTOMER_EDIT_IMPACT_FUNCTION,
    CUSTOMER_READ_IMPACT_FUNCTION,
    GLOBAL_EDIT_INVESTMENT,
    GLOBAL_READ_INVESTMENT,
    CUSTOMER_EDIT_INVESTMENT,
    CUSTOMER_READ_INVESTMENT,
    CUSTOMER_READ_CUSTOMER,
    GLOBAL_EDIT_HIERARCHY,
    GLOBAL_READ_HIERARCHY,
    CUSTOMER_EDIT_HIERARCHY,
    CUSTOMER_READ_HIERARCHY,
];

export const ACTION = {
    ACTION_READ: 'R',
    ACTION_WRITE: 'R/W',
    ACTION_EDIT: 'U',
};

export const FORM_ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
};

export const MOVE = 'MOVE';
export const VIEW = 'VIEW';
export const EDIT = 'EDIT';
export const RENAME = 'RENAME';
export const DELETE = 'DELETE';
export const AGGREGATE = 'AGGREGATE';
export const RESCORE = 'RESCORE';
export const MRP = 'MRP';
export const BULK_DELETE = 'BULK_DELETE';
export const NEW_FOLDER = 'NEW_FOLDER';
export const NEW_ASSET = 'NEW_ASSET';
export const NEW_GROUP = 'NEW_GROUP';
export const BULK_MOVE = 'BULK_MOVE';
export const TAG_SELECT = 'TAG_SELECT';

export const CUSTOMER_LIST_DASHBOARD = 'CUSTOMER_LIST_DASHBOARD';
export const CUSTOMER_LIST_PORTFOLIO = 'CUSTOMER_LIST_PORTFOLIO';
export const CUSTOMER_LIST_ALLCUSTOMER = 'CUSTOMER_LIST_ALLCUSTOMER';

export const NOT_ALLOWED = 'NOT_ALLOWED';

export const OKTA_CONFIG = {
    clientId:
        (window && window._env_?.REACT_APP_OKTA_CLIENT_ID) || process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: (window && window._env_?.REACT_APP_OKTA_ISSUER) || process.env.REACT_APP_OKTA_ISSUER,
    redirectUri:
        (window && window._env_?.REACT_APP_REDIRECT_URI) || process.env.REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
    disableHttpsCheck: true,
};

export const MAPBOX_CONFIG = {
    accessToken: (window && window._env_?.MAPBOX_PK_VALUE) || process.env.MAPBOX_PK_VALUE,
    host: (window && window._env_?.MAPBOX_HOST) || process.env.MAPBOX_HOST,
}

export const subscriberRoleMap = {
    trial: ['GRP_CLIM_SALES_LEAD', 'GRP_CLIM_TECHNICAL_SUPPORT'],
    active: [
        'GRP_CLIM_CS_LEAD',
        'GRP_CLIM_CUSTOMER_SUCCESS_MANAGER',
        'GRP_CLIM_CS_MANAGER',
        'GRP_CLIM_TECHNICAL_SUPPORT',
    ],
};

export const GroupAndResourceMapping = () => ({
    GRP_CLIM_DEVELOPER: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'TrialCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'ProcessingStatusDashboard',
                'ActiveCustomerExport',
                'TrialCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['ControlPermissions'],
            access: 'R/W',
        },
        {
            resource: [
                'AllAssetsLibrary',
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
                'Customers',
            ],
            access: 'R/W',
        },
    ],
    GRP_CLIM_PRODUCT_MANAGER: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'TrialCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'ProcessingStatusDashboard',
                'ActiveCustomerExport',
                'TrialCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['ControlPermissions'],
            access: 'R/W',
        },
        {
            resource: [
                'AllAssetsLibrary',
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
                'Customers',
            ],
            access: 'R/W',
        },
    ],

    GRP_CLIM_SALES_LEAD: [
        {
            resource: ['ActiveCustomerPortfolios'],
            access: 'R',
        },
        {
            resource: [
                'TrialCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'ActiveCustomerExport',
                'TrialCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['ProcessingStatusDashboard'],
            access: 'R/W',
        },
        {
            resource: ['ControlPermissions'],
            access: 'R/W',
        },
        {
            resource: [
                'AllAssetsLibrary',
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
        {
            resource: ['Customers'],
            access: 'R/W',
        },
    ],
    GRP_CLIM_SALES_MANAGER: [
        {
            resource: ['ActiveCustomerPortfolios', 'AllAssetsLibrary'],
            access: 'R',
        },
        {
            resource: ['TrialCustomerPortfolios'],
            access: 'R/W',
        },
        {
            resource: ['DemoAndSamplePortfolios', 'DemoCustomerExport'],
            access: 'R/W',
        },
        {
            resource: ['ProcessingStatusDashboard'],
            access: 'R/W',
        },
        {
            resource: ['ControlPermissions', 'ActiveCustomerExport', 'TrialCustomerExport'],
            access: 'R/W',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
        {
            resource: ['Customers'],
            access: 'R/W',
        },
    ],

    GRP_CLIM_INTERNAL_USER: [
        {
            resource: ['ActiveCustomerPortfolios', 'AllAssetsLibrary'],
            access: 'R',
        },
        {
            resource: ['DemoAndSamplePortfolios', 'DemoCustomerExport'],
            access: 'R/W',
        },
        {
            resource: ['ProcessingStatusDashboard'],
            access: 'R/W',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
        {
            resource: ['Customers'],
            access: 'R/W',
        },
    ],

    GRP_CLIM_CS_LEAD: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'ActiveCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['TrialCustomerPortfolios'],
            access: 'R',
        },
        {
            resource: ['AllAssetsLibrary', 'Customers'],
            access: 'R/W',
        },
        {
            resource: ['TrialCustomerPortfolios', 'TrialCustomerExport'],
            access: 'NOT_ALLOWED',
        },
        {
            resource: ['ProcessingStatusDashboard'],
            access: 'R/W',
        },
        {
            resource: ['ControlPermissions'],
            access: 'R/W',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
    ],
    GRP_CLIM_CUSTOMER_SUCCESS_MANAGER: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'ActiveCustomerExport',
                'AllAssetsLibrary',
                'TrialCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: [],
            access: 'NOT_ALLOWED',
        },
        {
            resource: ['TrialCustomerPortfolios'],
            access: 'R',
        },
        {
            resource: ['DemoAndSamplePortfolios', 'DemoCustomerExport'],
            access: 'R/W',
        },
        {
            resource: ['ProcessingStatusDashboard'],
            access: 'R/W',
        },
        {
            resource: ['ControlPermissions'],
            access: 'R/W',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
        {
            resource: ['Customers'],
            access: 'R/W',
        },
    ],

    GRP_CLIM_DATA_ANALYST: [
        {
            resource: ['ActiveCustomerPortfolios', 'TrialCustomerPortfolios'],
            access: 'R',
        },
        {
            resource: [
                'DemoAndSamplePortfolios',
                'DemoCustomerExport',
                'ActiveCustomerExport',
                'TrialCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['ProcessingStatusDashboard', 'ControlPermissions', 'Customers'],
            access: 'NOT_ALLOWED',
        },
        {
            resource: [
                'AllAssetsLibrary',
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
    ],
    GRP_CLIM_RESEARCH_ANALYST: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'AllAssetsLibrary',
                'TrialCustomerPortfolios',
                'ProcessingStatusDashboard',
                'ControlPermissions',
                'Customers',
            ],
            access: 'NOT_ALLOWED',
        },
        {
            resource: [
                'DemoCustomerExport',
                'DemoAndSamplePortfolios',
                'ActiveCustomerExport',
                'TrialCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['ReleaseNotes'],
            access: 'R',
        },
        {
            resource: [],
            access: 'R',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R/W',
        },
    ],
    GRP_CLIM_TECHNICAL_SUPPORT: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'ProcessingStatusDashboard',
                'TrialCustomerPortfolios',
                'ActiveCustomerExport',
                'DemoCustomerExport',
                'TrialCustomerExport',
                'AllAssetsLibrary',
                'Customers',
                'ControlPermissions',
            ],
            access: 'R/W',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
            ],
            access: 'R',
        },
    ],
    GRP_CLIM_CLIMHUB_LOGIN_USER: [
        {
            resource: ['NatureRisk'],
            access: 'R/W',
        },
    ],

    CLIM_ADMIN: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'TrialCustomerPortfolios',
                'ViewFolder',
                'AllAssetsLibrary',
                'ViewFolderEntitlement',
                'ExportLibrary',
                'ProcessingStatusDashboard',
                'ActiveCustomerExport',
                'TrialCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['Customers'],
            access: 'NOT_ALLOWED',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
    ],
    CLIM_EDITOR: [
        {
            resource: [
                'ActiveCustomerPortfolios',
                'TrialCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'ProcessingStatusDashboard',
                'ViewFolder',
                'AllAssetsLibrary',
                'ExportLibrary',
                'ActiveCustomerExport',
                'TrialCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'R/W',
        },
        {
            resource: ['ProcessingStatusDashboard', 'Customers', 'ViewFolderEntitlement'],
            access: 'NOT_ALLOWED',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
    ],
    CLIM_VIEWER: [
        {
            resource: ['ViewFolder'],
            access: 'R/W',
        },
        {
            resource: [
                'ActiveCustomerPortfolios',
                'TrialCustomerPortfolios',
                'DemoAndSamplePortfolios',
                'AllAssetsLibrary',
            ],
            access: 'R',
        },
        {
            resource: [
                'ProcessingStatusDashboard',
                'ViewFolderEntitlement',
                'ExportLibrary',
                'ActiveCustomerExport',
                'TrialCustomerExport',
                'DemoCustomerExport',
            ],
            access: 'NOT_ALLOWED',
        },
        {
            resource: [
                'AssetTypesLibrary',
                'ImpactFunctionsLibrary',
                'Hazards',
                'ReleaseNotes',
                'MethodologyDocs',
                'RiskFactorLibrary',
            ],
            access: 'R',
        },
    ],
    CLIM_CLIMHUB_LOGIN_USER: [
        {
            resource: ['NatureRisk'],
            access: 'R/W',
        },
    ],
});

export const ALLOWED_SUPER_ADMIN = [
    'GRP_CLIM_DEVELOPER',
    'GRP_CLIM_PRODUCT_MANAGER',
    'GRP_CLIM_SALES_LEAD',
    'GRP_CLIM_SALES_MANAGER',
    'GRP_CLIM_CS_LEAD',
    'GRP_CLIM_CUSTOMER_SUCCESS_MANAGER',
    'GRP_CLIM_TECHNICAL_SUPPORT',
];

export const ALLOWED_CLIENT_ADMIN = ['CLIM_ADMIN'];

export const ALLOWED_CUSTOMER_EDIT_ROLES = [
    'GRP_CLIM_DEVELOPER',
    'GRP_CLIM_PRODUCT_MANAGER',
    'GRP_CLIM_CS_LEAD',
];

export const RBAC_USERS_ROLES_TYPES = {
    OKTA_ROLE_ALL: [
        'GRP_CLIM_DEVELOPER',
        'GRP_CLIM_PRODUCT_MANAGER',
        'GRP_CLIM_SALES_LEAD',
        'GRP_CLIM_CS_LEAD',
        'GRP_CLIM_DATA_ANALYST',
        'GRP_CLIM_TECHNICAL_SUPPORT',
    ],
    OKTA_ROLE: [
        'GRP_CLIM_SALES_MANAGER',
        'GRP_CLIM_INTERNAL_USER',
        'GRP_CLIM_CUSTOMER_SUCCESS_MANAGER',
        'CLIM_ADMIN',
        'CLIM_EDITOR',
        'CLIM_VIEWER',
    ],
    OKTA_ROLE_NA: ['GRP_CLIM_RESEARCH_ANALYST'],
};

export const RBAC_ROLES = {
    GRP_CLIM_CS_LEAD: 'CS_LEAD',
    GRP_CLIM_CUSTOMER_SUCCESS_MANAGER: 'CUSTOMER_SUCCESS_MANAGER',
    GRP_CLIM_DATA_ANALYST: 'DATA_ANALYST',
    GRP_CLIM_DEVELOPER: 'DEVELOPER',
    GRP_CLIM_PRODUCT_MANAGER: 'PRODUCT_MANAGER',
    GRP_CLIM_RESEARCH_ANALYST: 'RESEARCH_ANALYST',
    GRP_CLIM_SALES_LEAD: 'SALES_LEAD',
    GRP_CLIM_SALES_MANAGER: 'SALES_MANAGER',
    GRP_CLIM_INTERNAL_USER: 'INTERNAL_USER',
    GRP_CLIM_TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
    CLIM_ADMIN: 'ADMIN',
    CLIM_EDITOR: 'EDITOR',
    CLIM_VIEWER: 'VIEWER',
};

export const RBAC_ROLES_NB = {
    GRP_CLIM_CLIMHUB_LOGIN_USER: 'LOGIN_USER',
    CLIM_CLIMHUB_LOGIN_USER: 'LOGIN_USER',
};

export const PRODUCT_MANAGER = 'GRP_CLIM_PRODUCT_MANAGER';

export const USER_CONSENT = 'CLIMANOMICS_USER_CONSENT';

export const CONSENT_REQUIRED_ERROR = 'consent_required';

export const HEAT_MAP_DATA = [
    { value: 'wildfireFreqFWI', label: 'Wildfire' },
    // { value: 'fluvialflood', label: 'Fluvial Flood' },
    { value: 'droughtFreqspei', label: 'Drought' },
    { value: 'tropical_cyclone', label: 'Tropical Cyclone' },
];

export const SCENARIO_MAPPING = [
    { value: 'ssp126', label: 'Low (RCP 2.6/SSP1-2.6)'},
    { value: 'ssp245', label: 'Medium (RCP 4.5/SSP2-4.5)'},
    { value: 'ssp370', label: 'Medium-High (RCP 7.0/SSP3-7.0)'},
    { value: 'ssp585', label: 'High (RCP 8.5/SSP5-8.5)'},
];
