import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiGet } from 'hooks/useApiGet';
import MapBoxMap from 'components/MapBoxMap';
import MapWrapper from 'components/LocationMap';
import { IS_MAP_ACTIVE_LS_KEY } from 'components/components.constants';
import { getAllAssetsForFolder, getCustomerAssets } from '../../services/assetService';
import riskColor from '../../utils/riskColor';
import { useHierarchyContext } from '../../context/HierarchyContext';

function InvestmentAssetsLocationMap({
    entityType,
    entityId,
    riskDataFilters,
    investmentId,
    folderId,
    viewId,
    customerId,
    tableData,
}) {
    const { currentPath } = useHierarchyContext();
    const customerIdFromPath = currentPath.substring(1).split('/')?.[1];

    const [locationMapThreshold, setLocationMapThreshold] = useState(null);
    const [isMapActive, setIsMapActive] = useState(() => {
        const storedValue = localStorage.getItem(IS_MAP_ACTIVE_LS_KEY);
        if (storedValue === null) {
            localStorage.setItem(IS_MAP_ACTIVE_LS_KEY, JSON.stringify(true));
            return true;
        }
        return JSON.parse(storedValue);
    });
    const [mapboxEnabled, setMapboxEnabled] = useState(null);

    const { data: appConfigs, isLoading: configLoading } = useApiGet('api/appConfig', {
        keys: ['location_map_threshold', 'mapbox_enabled'],
    });

    const formattedLocationMapThreshold = !Number.isNaN(locationMapThreshold)
        ? Number(locationMapThreshold).toLocaleString()
        : 'N/A';

    useEffect(() => {
        const handleMapActiveChanged = () => {
            const storedValue = localStorage.getItem(IS_MAP_ACTIVE_LS_KEY);
            setIsMapActive(storedValue ? JSON.parse(storedValue) : true);
        };

        window.addEventListener('mapActiveChanged', handleMapActiveChanged);
        return () => window.removeEventListener('mapActiveChanged', handleMapActiveChanged);
    }, []);

    useEffect(() => {
        if (!configLoading && appConfigs) {
            const locationThresholdConfig = appConfigs.results.find(
                (i) => i.key === 'location_map_threshold'
            );
            const mapboxEnabledConfig = appConfigs.results.find(
                (i) => i.key === 'mapbox_enabled'
            );

            setLocationMapThreshold(
                locationThresholdConfig ? parseInt(locationThresholdConfig.value, 10) : null
            );
            setMapboxEnabled(
                mapboxEnabledConfig
                    ? mapboxEnabledConfig.value === 'true' || mapboxEnabledConfig.value === true
                    : false
            );
        }
    }, [configLoading, appConfigs]);

    const mapProhibited =
        customerIdFromPath === '2109' ||
        customerIdFromPath === '4' ||
        customerIdFromPath === '1122';

    if (mapProhibited) {
        return null;
    }

    const [locations, setLocations] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [loading, setLoading] = useState(true);
    const [mapUnavailable, setMapUnavailable] = useState(false);
    const [transformedLocations, setTransformedLocations] = useState([]);

    const addRiskInfoToInvestmentLocations = (locationsArray, assetTableData) =>
        locationsArray.map((location) => {
            const findTableData = assetTableData.find(
                (asset) => asset.entityId === location.asset_id,
            );
            const assetObject = findTableData || {
                absoluteValue: '$0.00',
                relativeValue: '0.0 %',
            };
            return {
                ...location,
                ...assetObject,
                color: riskColor.determineColor(assetObject.relative_value, 'relative'),
            };
        });

    const updateLocationEntityNames = (locs, assets) => {
        locs.forEach((loc) => {
            const matchingAsset = assets.find((asset) => asset.asset_id === loc.asset_id);
            if (matchingAsset) loc.entity_name = matchingAsset.asset_name;
        });
        return locs;
    };

    const transformData = (assetLocations) =>
        assetLocations.map((location) => ({
            assetTypeId: location.asset_folder_id || null,
            customerId: customerId || null,
            assetValue: location.total_value || null,
            assetsTags: [],
            assetsTorefId: { refName: location.entity_name || '' },
            emissions: location.relative_value || 0,
            id: location.entityId || location.asset_id,
            locations: [
                {
                    lat: location.lat,
                    long: location.long,
                    absolute_value: location.absolute_value,
                    country: location.country || '',
                    scoringStatus: location.scoringStatus || '',
                    status: location.status || null,
                    street_address: location.street_address || '',
                    color: location.color || '',
                },
            ],
            name: location.entity_name || '',
            refId: location.entityId ? String(location.entityId) : '',
        }));

    const fetchAllAssetsForFolder = async () => {
        try {
            const pageSize = 40000;
            const firstPageResponse = await getAllAssetsForFolder({
                customerId,
                folderId,
                viewId,
                page: 1,
                pageSize,
            });

            const { totalPages } = firstPageResponse;
            const allResults = firstPageResponse.results;

            if (!totalPages || totalPages <= 1) {
                return allResults;
            }

            const promises = [];
            for (let currentPage = 2; currentPage <= totalPages; currentPage += 1) {
                promises.push(
                    getAllAssetsForFolder({
                        customerId,
                        folderId,
                        viewId,
                        page: currentPage,
                        pageSize,
                    })
                );
            }

            const responses = await Promise.all(promises);
            responses.forEach((response) => {
                allResults.push(...response.results);
            });

            return allResults;
        } catch (error) {
            console.error('Error fetching assets:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (!isMapActive) {
            setLoading(false);
            return;
        }

        async function fetchData() {
            if (!entityId || locationMapThreshold === null) {
                return;
            }
            setLoading(true);
            try {
                const assetCountData = await getCustomerAssets(customerId, { assetcount: true });

                if (
                    assetCountData.active_asset_count < locationMapThreshold ||
                    folderId ||
                    viewId
                ) {
                    const investmentAssetLocationsResponse = await fetchAllAssetsForFolder();

                    if (investmentAssetLocationsResponse.length < locationMapThreshold) {
                        const investmentAssetLocations =
                            investmentAssetLocationsResponse.reduce(
                                (accumulator, currentAsset) =>
                                    accumulator.concat(currentAsset.location_list),
                                []
                            );

                        const investmentAssetLocationsWithRisks =
                            addRiskInfoToInvestmentLocations(
                                investmentAssetLocations,
                                tableData,
                                riskDataFilters
                            );

                        const investmentAssetLocationsWithRisksAndNames =
                            updateLocationEntityNames(
                                investmentAssetLocationsWithRisks,
                                investmentAssetLocationsResponse
                            );

                        setShowMap(true);
                        setLocations(investmentAssetLocationsWithRisksAndNames);

                        const transformedData = transformData(
                            investmentAssetLocationsWithRisksAndNames
                        );
                        setTransformedLocations(transformedData);
                    } else {
                        setMapUnavailable(true);
                    }
                } else {
                    setMapUnavailable(true);
                }
            } catch (error) {
                console.error('Error fetching data', error);
                setMapUnavailable(true);
            } finally {
                setLoading(false);
            }
        }

        if (mapboxEnabled !== null && locationMapThreshold !== null) {
            fetchData();
        }
    }, [
        isMapActive,
        riskDataFilters,
        entityId,
        entityType,
        folderId,
        investmentId,
        viewId,
        locationMapThreshold,
        mapboxEnabled,
    ]);

    if (!isMapActive) {
        return (
            <div className="spg-col-6 spg-py-lg">
                <h5>
                    The map is currently disabled. To enable the map, please click the Map Active
                    switch in the user menu.
                </h5>
            </div>
        );
    }

    if (loading || configLoading || mapboxEnabled === null) {
        return <p>Loading map configuration...</p>;
    }

    if (mapUnavailable) {
        return (
            <div className="spg-col-6 spg-py-lg">
                <h5>
                    The map is temporarily unavailable for portfolio asset counts of {formattedLocationMapThreshold} and above
                    to prevent performance issues in loading your portfolio.
                </h5>
            </div>
        );
    }

    if (showMap) {
        return mapboxEnabled ? (
            <MapBoxMap assetData={transformedLocations} isPortfolioView={true} />
        ) : (
            <MapWrapper locations={locations} displayLegend />
        );
    }

    return null;
}

InvestmentAssetsLocationMap.propTypes = {
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.number,
    folderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleClick: PropTypes.func,
    investmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    riskDataFilters: PropTypes.object,
    tableData: PropTypes.array,
};

export default React.memo(InvestmentAssetsLocationMap);
