export const SEARCH_INPUT_LABEL = 'Search:';
export const INPUT_LENGTH = 50;

export const DEFAULT_SPINNER_SIZE = 48;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_DATA = { clicked: false, page: 0, rowsPerPage: DEFAULT_PAGE_SIZE };

export const PAGINATION_PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 30, label: '30'},
];

export const DEFAULT_COL_DEF = {
    sortable: true,
    resizable: true,
    wrapHeaderText: true,
    tooltipShowDelay: 200
}

export const DARK_THEME_CLASS_NAME = 'spg-dark';
export const IS_DARK_THEME_LS_KEY = 'CLIMANOMICS_IS_DARK_THEME';
export const IS_MAP_ACTIVE_LS_KEY = 'CLIMANOMICS_IS_MAP_ACTIVE';
