import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Icon, Image } from '@spglobal/react-components';
import { EXTERNAL_LINK } from '@spglobal/koi-icons';
import { Size } from '@spglobal/koi-helpers';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import imageCO2 from '../components/Methodology/assets/images/image3.jpg';
import image25 from '../components/Methodology/assets/images/image25.png';
import image26 from '../components/Methodology/assets/images/image26.png';
import image27 from '../components/Methodology/assets/images/image27.png';
import image28 from '../components/Methodology/assets/images/image28.png';
import image29 from '../components/Methodology/assets/images/image29.png';
import image30 from '../components/Methodology/assets/images/image30.png';
import { getFile } from '../services/fileDownloadService';
import IF_AT_Updates_Sep2024 from '../components/Methodology/assets/IF_AT_Updates_Sep2024.xlsx';
import IF_AT_Updates_Jan2025 from '../components/Methodology/assets/IF_AT_Updates_Jan2025.xlsx';

export default function WhatsNew() {
    const fileKey = 'Climanomics_Methodology_22Aug_2024.pdf';

    const handleDocumentClick = async () => {
        try {
            const response = await getFile(fileKey);

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileKey); // Set the file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }
    return (
        <>
            <BreadcrumbTrail rootTitle={'What\'s New'} />
            <div className="spg-p-md">
                <h3 className="spg-heading spg-heading--medium spg-mt-md">January 23, 2025</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updated Water Stress hazard data and updates to vulnerability modeling to be available soon
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to announce that we are rolling out updated hazard data for Water Stress as well as updates to a number of Impact Functions as part of our efforts to keep our modeling dynamic and up to date.
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">Water Stress data update</h3>
                <p className="spg-text spg-text-paragraph">
                    Climanomics will now be using location-specific data from the World Resources Institute’s (WRI) Aqueduct 4.0 as an indicator of competition for water resources. This indicator is defined as the ratio of demand for water by human society divided by available water supply. WRI offers projections only for the scenarios SSP3-7.0 (business-as-usual), SSP1-2.6 (optimistic), and SSP5-8.5 (pessimistic), and for the years 2030, 2050, and 2080.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Climanomics uses those 3 yearly values in each scenario to interpolate values for all years between 2030 and 2080, and then creates decadal averages from the interpolated yearly values. Water Stress is assumed to be constant from 2020 to 2030 and from 2080 to the end of the century. The scenario SSP2-4.5 is interpolated based on the water stress index projections for SSP1-2.6, using the relative global mean surface air temperature (GSAT) as the scaling factor. The Water Stress index is a continuous variable capped at a range of 0 to 1.
                </p>
                <p className="spg-text spg-text-paragraph">
                    When fully implemented, this data update may lead to a change in your risk values.
                </p>

                <h3 className="spg-heading spg-heading--medium spg-mt-md">Impact Functions enhancements</h3>
                <p className="spg-text spg-text-paragraph">
                    In addition to the Water Stress data update, we have also enhanced 30 existing Impact Functions and added 9 new ones. The Impact Function enhancements primarily impact Extreme Temperature and cover a range of asset types from real estate to agriculture. Moreover, 9 new Impact Functions have been added and applied to the Highway-Investor asset type.
                </p>
                <p className="spg-text spg-text-paragraph">
                    We implement these updates as part of our continuing effort to improve the precision and accuracy of our climate risk models. For a full inventory of Impact Function updates and releases, please refer to <Link to={IF_AT_Updates_Jan2025} target="_blank" rel="noopener noreferrer"> this document</Link>.
                </p>

                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    When will I see these new and updated impact functions in my portfolio?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    These updates will be applied to Climanomics portfolios within January 2025.
                </p>
                <p className="spg-text spg-text-paragraph">
                    For additional questions on these updates, please contact your Relationship Manager or <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                        Climanomics_Support@spglobal.com
                    </a>.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">September 25, 2024</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">Landslide risk and updates to vulnerability modeling to be available soon</h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to announce that Landslide risk will soon be available for physical assets uploaded in Climanomics. We have developed more than 40 new impact functions for Landslide, assigned to the relevant asset types ranging from real estate to agricultural assets, enabling Climanomics to calculate the financial impact of Landslide at the asset level and aggregated up to portfolio level.
                </p>
                <p className="spg-text spg-text-paragraph">
                    In addition to the new Landslide impact functions, we have also developed 8 new impact functions that are relevant for wind farms across different hazards. Meanwhile, 55 existing Impact Functions have been updated based on new available data and/or peer reviewed literature. We have also made updates to a few asset types. We update Impact Functions and Asset Types as part of our continuing effort to improve the precision and accuracy of our models.
                </p>
                <p className="spg-text spg-text-paragraph">
                    For a full inventory of the new and updated impact functions and asset types, please refer to <Link to={IF_AT_Updates_Sep2024} target="_blank" rel="noopener noreferrer">this document</Link>.
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    When will I see these new and updated impact functions in my portfolio?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    From September 20, 2024 onwards, the new and updated impact functions will be available for new assets created or uploaded to Climanomics.
                    This means that if you create/upload assets to Climanomics on or after September 20th, the climate risk of your assets will already include Landslide and will be modeled based on the latest versions of the impact functions.
                </p>
                <p className="spg-text spg-text-paragraph">
                    These updates will only be applied to existing portfolios (assets uploaded in Climanomics before September 20, 2024) after we rescore your portfolio. We have scheduled the rescore of all existing active and trial portfolios to start on October 1, 2024. We will be announcing the completion of the rescore with another banner. 
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    Will I have access to the data prior to the implementation of these updates?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    Yes, we will be storing your portfolio's previous risk values in CSV files before we rescore your portfolio and these files will be made available to you upon request.
                </p>
                <p className="spg-text spg-text-paragraph">
                    For additional questions on these updates, please contact your Relationship Manager or <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                        Climanomics_Support@spglobal.com
                    </a>. 
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">July 25, 2024</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">Landslide hazard</h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to announce that we have modeled Landslide as part of our effort
                    to help clients meet the EU Taxonomy requirements. For this first release of
                    Landslide, we are releasing the hazard data, which can be used to determine
                    exposure. Landslide hazard data will be available at the asset level hazard
                    graph. <Image src={image29} style={{ width: '60rem', height: 'auto' }} />
                </p>
                <p className="spg-text spg-text-paragraph">
                    Hazard data can also be exported from the data export builder in Climanomics.
                    Landslide hazard data can be exported if you select the following outputs from
                    the export builder: 1) Physical Risk - Hazard Metrics and 2) Physical Risk -
                    Hazard Metrics Baseline.{' '}
                    <Image src={image30} style={{ width: '60rem', height: 'auto' }} />
                    Because Landslide does not yet have impact functions, it will not have the
                    Modeled Average Annual Loss values. Impact functions is planned for release by
                    early October 2024, afterwhich all assets and portfolios will have MAAL values.
                </p>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">Rescored portfolios </h1>
                <p className="spg-text spg-text-paragraph">
                    We have completed the integration of updates to our impact functions with the
                    rescore of all portfolios. If your portfolio had asset types linked to the
                    updates, the assets' risk values and aggregated MAAL may have changed.
                </p>

                <p className="spg-text spg-text-paragraph">
                    The rescored portfolios now reflect impact functions that were updated in June
                    2024 as well as updates made in December 2023. The full set of updated impact
                    function are in the table below.
                </p>

                <table className="spg-table">
                    <thead>
                        <tr>
                            <th className="spg-p-md">No.</th>
                            <th className="spg-p-md">Original Impact Function Name</th>
                            <th className="spg-p-md">Updated Impact Function Name</th>
                            <th className="spg-p-md">Affected Asset Type</th>
                            <th className="spg-p-md">Asset Type ID</th>
                            <th className="spg-p-md">Description of Change and Rationale</th>
                            <th className="spg-p-md">Impact</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Drought Conditions-Retail-Investor-v1</td>
                            <td>Drought Conditions-Retail-Investor-v2</td>
                            <td>
                                1. Shopping Center - Investor
                                <br />
                                2. Fitness Club - Investor
                            </td>
                            <td>
                                200193
                                <br />
                                200194
                            </td>
                            <td>
                                S&P Global has excluded the "Business Interruption" pathway
                                associated with water main breaks. This pathway was removed due to
                                lack of data indicating that businesses lose water, and subsequently
                                shut down, for any significant amount of time as a result of these
                                water main break events.
                            </td>
                            <td>
                                Because an impact pathway has been excluded, this model now shows
                                decreased vulnerability to drought for the Shopping Center -
                                Investor and Fitness Club - Investor asset types, and you should
                                expect to see drought risk for Shopping Center - Investor and
                                Fitness Club - Investor assets decrease relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Coastal Flooding Frequency-Retail-Investor-v1</td>
                            <td>Coastal Flooding Frequency-Retail-Investor-v2</td>
                            <td>
                                1. Shopping Center - Investor
                                <br />
                                2. Fitness Club - Investor
                            </td>
                            <td>
                                200193
                                <br />
                                200194
                            </td>
                            <td>
                                S&P Global has updated the 'Business Interruption' impact pathway;
                                the assumption used for days of interruption is now updated for real
                                estate asset types.
                            </td>
                            <td>
                                Because the underlying assumption used in the business interruption
                                impact pathway was updated, this model now shows increased
                                vulnerability to coastal flooding for the Shopping Center - Investor
                                and Fitness Club - Investor asset types, and you should expect to
                                see an increase in coastal flooding risk for Shopping Center -
                                Investor and Fitness Club - Investor assets relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Pluvial Flooding Frequency-Retail-Investor-v1</td>
                            <td>Pluvial Flooding Frequency-Retail-Investor-v2</td>
                            <td>
                                1. Shopping Center - Investor
                                <br />
                                2. Fitness Club - Investor
                            </td>
                            <td>
                                200193
                                <br />
                                200194
                            </td>
                            <td>
                                S&P Global has updated the 'Business Interruption' impact pathway;
                                the assumption used for days of interruption is now updated for real
                                estate asset types.
                            </td>
                            <td>
                                Because the underlying assumption used in the business interruption
                                impact pathway was updated, this model now shows increased
                                vulnerability to pluvial flooding for the Shopping Center - Investor
                                and Fitness Club - Investor asset types, and you should expect to
                                see an increase in pluvial flooding risk for Shopping Center -
                                Investor and Fitness Club - Investor assets relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Wildfire Conditions-Office-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Office-Rural-Owner/Occupier-v2</td>
                            <td>Office - Owner/Occupier</td>
                            <td>200149</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Office - Owner/Occupier asset type, and you should expect to see
                                wildfire risk for Office - Owner/Occupier assets increase relative
                                to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Wildfire Conditions-Office-Tenant-v1</td>
                            <td>Wildfire Conditions-Office-Rural-Tenant-v2</td>
                            <td>Office - Tenant</td>
                            <td>200151</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Office - Tenant asset type, and you should expect to see wildfire
                                risk for Office - Tenant assets increase relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Wildfire Conditions-Hotel-Tenant-v1</td>
                            <td>Wildfire Conditions-Hotel/Rural-Tenant-v2</td>
                            <td>Hotel - Tenant</td>
                            <td>200468</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Hotel - Tenant asset type, and you should expect to see wildfire
                                risk for Office - Tenant assets increase relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Wildfire Conditions-Data Center-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Data Center/Rural-Owner/Occupier-v2</td>
                            <td>Data Center - Owner/Occupier</td>
                            <td>200150</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Data Center - Owner/Occupier asset type, and you should expect to
                                see wildfire risk for Data Center - Owner/Occupier assets increase
                                relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Wildfire Conditions-Data Center-Tenant-v1</td>
                            <td>Wildfire Conditions-Data Center/Rural-Tenant-v2</td>
                            <td>Data Center - Tenant</td>
                            <td>200116</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Data Center - Tenant asset type, and you should expect to see
                                wildfire risk for Data Center - Tenant assets increase relative to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Wildfire Conditions-E Suite-Tenant-v1</td>
                            <td>Wildfire Conditions-E Suite-Rural-Tenant-v2</td>
                            <td>Executive Suite - Tenant</td>
                            <td>200206</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Executive Suite - Tenant asset type, and you should expect to see
                                wildfire risk for Executive Suite - Tenant assets increase relative
                                to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Wildfire Conditions-Retail-Tenant-v1</td>
                            <td>Wildfire Conditions-Retail-Rural-Tenant-v2</td>
                            <td>Retail - Tenant</td>
                            <td>200152</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Retail - Tenant asset type, and you should expect to see wildfire
                                risk for Retail - Tenant assets increase relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Wildfire Conditions-Paper Product Mills/Rural-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Paper Product Mills/Rural-Owner/Occupier-v2</td>
                            <td>Paper Product Mills</td>
                            <td>200407</td>
                            <td>
                                The underlying assumption for the likelihood of direct impact for
                                rural areas has been adjusted for the Business Interruption and
                                Physical Damage impact pathways.
                            </td>
                            <td>
                                Because the underlying assumption for the likelihood of direct
                                impact for rural areas has been updated, this model now shows
                                decreased vulnerability to wildfire conditions for the Paper Product
                                Mills asset type, and you should expect to see wildfire risk for
                                Paper Product Mill assets decrease relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Wildfire Conditions-Paper Mill (1) - Rural-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Paper Mill (1) - Rural-Owner/Occupier-v2</td>
                            <td>
                                <ol>
                                    <li>Paper Mill (1) - Owner/Occupier (High Withdrawal)</li>
                                    <li>Paper Mill (1) - Owner/Occupier (Low Withdrawal)</li>
                                    <li>Paper Mill (1) - Owner/Occupier (Medium Withdrawal)</li>
                                </ol>
                            </td>
                            <td>
                                <ol>
                                    <li>200113</li>
                                    <li>200115</li>
                                    <li>200114</li>
                                </ol>
                            </td>
                            <td>
                                The underlying assumption for the likelihood of direct impact for
                                rural areas has been adjusted for the Business Interruption and
                                Physical Damage impact pathways.
                            </td>
                            <td>
                                Because the underlying assumption for the likelihood of direct
                                impact for rural areas has been updated, this model now shows
                                decreased vulnerability to wildfire conditions for the Paper Mill
                                (Owner/Occupier) asset type, and you should expect to see wildfire
                                risk for Paper Mill (Owner/Occupier) assets decrease relative to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Avg Daily Max Temp Chg-Light Manufacturing-Owner/Occupier-v1</td>
                            <td>Avg Daily Max Temp Chg-Light Manufacturing-Owner/Occupier-v2</td>
                            <td>Light Manufacturing - Owner/Occupier</td>
                            <td>200462</td>
                            <td>
                                The financial ratios used to construct cooling cost pathway was
                                adjusted to correct a previous value.
                            </td>
                            <td>
                                Because the underlying financial ratio for the cooling cost impact
                                pathway has been updated, this model now shows increased
                                vulnerability to extreme temperature for the Light Manufacturing -
                                Owner/Occupier asset type, and you should expect to see extreme
                                temperature risk for Light Manufacturing - Owner/Occupier assets
                                increase relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>Avg Daily Max Temp Chg-GeneralManufacturing-Owner/Occupier-v1</td>
                            <td>Avg Daily Max Temp Chg-GeneralManufacturing-Owner/Occupier-v2</td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                increased vulnerability to increasing average maximum temperatures
                                for the General Manufacturing - Owner/Occupier (Rural) (TIV) and
                                General Manufacturing - Owner/Occupier (Urban) (TIV) asset types.
                                You should expect to see temperature risk increase in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>Drought Conditions-GeneralManufacturing-Owner/Occupier-v1</td>
                            <td>Drought Conditions-GeneralManufacturing-Owner/Occupier-v2</td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                increased vulnerability to increased exposure to drought conditions
                                for the General Manufacturing - Owner/Occupier (Rural) (TIV) and
                                General Manufacturing - Owner/Occupier (Urban) (TIV) asset types.
                                You should expect to see drought risk increase in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>
                                Wildfire Conditions-GeneralManufacturing-Rural-Owner/Occupier-v2
                            </td>
                            <td>
                                Wildfire Conditions-GeneralManufacturing-Rural-Owner/Occupier-v3
                            </td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                decreased vulnerability to exposure to wildfire conditions for the
                                General Manufacturing - Owner/Occupier (Rural) (TIV) asset type. You
                                should expect to see wildfire risk decrease in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>17</td>
                            <td>
                                Wildfire Conditions-GeneralManufacturing-Urban-Owner/Occupier-v2
                            </td>
                            <td>
                                Wildfire Conditions-GeneralManufacturing-Urban-Owner/Occupier-v3
                            </td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                decreased vulnerability to exposure to wildfire conditions for the
                                General Manufacturing - Owner/Occupier (Urban) (TIV) asset type. You
                                should expect to see wildfire risk decrease in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>18</td>
                            <td>Trop Cyclone Frequency-GeneralManufacturing-Owner/Occupier-v2</td>
                            <td>Trop Cyclone Frequency-GeneralManufacturing-Owner/Occupier-v3</td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                decreased vulnerability to tropical cyclones for the General
                                Manufacturing - Owner/Occupier (Rural) (TIV) and General
                                Manufacturing - Owner/Occupier (Urban) (TIV) asset types. You should
                                expect to see tropical cyclone risk decrease in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>
                                Coastal Flooding Frequency-GeneralManufacturing-Owner/Occupier-v2
                            </td>
                            <td>
                                Coastal Flooding Frequency-GeneralManufacturing-Owner/Occupier-v3
                            </td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                decreased vulnerability to coastal flooding for the General
                                Manufacturing - Owner/Occupier (Rural) (TIV) and General
                                Manufacturing - Owner/Occupier (Urban) (TIV) asset types. You should
                                expect to see coastal flooding risk decrease in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>20</td>
                            <td>
                                Fluvial Flooding Frequency-GeneralManufacturing-Owner/Occupier-v2
                            </td>
                            <td>
                                Fluvial Flooding Frequency-GeneralManufacturing-Owner/Occupier-v3
                            </td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios used to construct this impact function were
                                updated based on a quantitative literature survey and data specific
                                to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to the financial ratios, this model now shows
                                decreased vulnerability to fluvial flooding for the General
                                Manufacturing - Owner/Occupier (Rural) (TIV) and General
                                Manufacturing - Owner/Occupier (Urban) (TIV) asset types. You should
                                expect to see fluvial flooding risk decrease in comparison to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>21</td>
                            <td>Water Stress Level-GeneralManufacturing-Owner/Occupier-v1</td>
                            <td>Water Stress Level-GeneralManufacturing-Owner/Occupier-v2</td>
                            <td>General Manufacturing - Owner/Occupier (TIV)</td>
                            <td>200154</td>
                            <td>
                                The financial ratios and impact pathways used to construct this
                                impact function were updated based on a quantitative literature
                                survey and data specific to manufacturing facilities.
                            </td>
                            <td>
                                Because of adjustments to impact pathway values and the financial
                                ratios, this model now shows decreased vulnerability to water stress
                                for the General Manufacturing - Owner/Occupier (Rural) (TIV) and
                                General Manufacturing - Owner/Occupier (Urban) (TIV) asset types.
                                You should expect to see water stress risk decrease in comparison to
                                previous values.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="spg-text spg-text-paragraph">
                    if you have questions or need assistance, please reach out to your Relationship
                    Manager or to Climanomics_Support@spglobal.com.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">June 10, 2024</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Upcoming Impact Functions update
                </h1>
                <p className="spg-text spg-text-paragraph">
                    As part of ongoing efforts to provide our clients with useful and actionable
                    risk outputs, we will be updating a number of Impact Functions to incorporate
                    new insights and data. The updates will affect 14 asset types, including the
                    commonly used Office, Data Center and Retail property types. If you have such
                    asset types, you will see a change in the risk outputs of your assets and your
                    portfolio. Below is a complete list of Impact Functions to be updated.
                </p>

                <table className="spg-table">
                    <thead>
                        <tr>
                            <th className="spg-p-md">No.</th>
                            <th className="spg-p-md">Original Impact Function Name</th>
                            <th className="spg-p-md">Updated Impact Function Name</th>
                            <th className="spg-p-md">Affected Asset Type</th>
                            <th className="spg-p-md">Asset Type ID</th>
                            <th className="spg-p-md">Description of Change and Rationale</th>
                            <th className="spg-p-md">Impact</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Drought Conditions-Retail-Investor-v1</td>
                            <td>Drought Conditions-Retail-Investor-v2</td>
                            <td>
                                1. Shopping Center - Investor
                                <br />
                                2. Fitness Club - Investor
                            </td>
                            <td>
                                200193
                                <br />
                                200194
                            </td>
                            <td>
                                S&P Global has excluded the "Business Interruption" pathway
                                associated with water main breaks. This pathway was removed due to
                                lack of data indicating that businesses lose water, and subsequently
                                shut down, for any significant amount of time as a result of these
                                water main break events.
                            </td>
                            <td>
                                Because an impact pathway has been excluded, this model now shows
                                decreased vulnerability to drought for the Shopping Center -
                                Investor and Fitness Club - Investor asset types, and you should
                                expect to see drought risk for Shopping Center - Investor and
                                Fitness Club - Investor assets decrease relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Coastal Flooding Frequency-Retail-Investor-v1</td>
                            <td>Coastal Flooding Frequency-Retail-Investor-v2</td>
                            <td>
                                1. Shopping Center - Investor
                                <br />
                                2. Fitness Club - Investor
                            </td>
                            <td>
                                200193
                                <br />
                                200194
                            </td>
                            <td>
                                S&P Global has updated the 'Business Interruption' impact pathway;
                                the assumption used for days of interruption is now updated for real
                                estate asset types.
                            </td>
                            <td>
                                Because the underlying assumption used in the business interruption
                                impact pathway was updated, this model now shows increased
                                vulnerability to coastal flooding for the Shopping Center - Investor
                                and Fitness Club - Investor asset types, and you should expect to
                                see an increase in coastal flooding risk for Shopping Center -
                                Investor and Fitness Club - Investor assets relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Pluvial Flooding Frequency-Retail-Investor-v1</td>
                            <td>Pluvial Flooding Frequency-Retail-Investor-v2</td>
                            <td>
                                1. Shopping Center - Investor
                                <br />
                                2. Fitness Club - Investor
                            </td>
                            <td>
                                200193
                                <br />
                                200194
                            </td>
                            <td>
                                S&P Global has updated the 'Business Interruption' impact pathway;
                                the assumption used for days of interruption is now updated for real
                                estate asset types.
                            </td>
                            <td>
                                Because the underlying assumption used in the business interruption
                                impact pathway was updated, this model now shows increased
                                vulnerability to pluvial flooding for the Shopping Center - Investor
                                and Fitness Club - Investor asset types, and you should expect to
                                see an increase in pluvial flooding risk for Shopping Center -
                                Investor and Fitness Club - Investor assets relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Wildfire Conditions-Office-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Office-Rural-Owner/Occupier-v2</td>
                            <td>Office - Owner/Occupier</td>
                            <td>200149</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Office - Owner/Occupier asset type, and you should expect to see
                                wildfire risk for Office - Owner/Occupier assets increase relative
                                to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Wildfire Conditions-Office-Tenant-v1</td>
                            <td>Wildfire Conditions-Office-Rural-Tenant-v2</td>
                            <td>Office - Tenant</td>
                            <td>200151</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Office - Tenant asset type, and you should expect to see wildfire
                                risk for Office - Tenant assets increase relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Wildfire Conditions-Hotel-Tenant-v1</td>
                            <td>Wildfire Conditions-Hotel/Rural-Tenant-v2</td>
                            <td>Hotel - Tenant</td>
                            <td>200468</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Hotel - Tenant asset type, and you should expect to see wildfire
                                risk for Office - Tenant assets increase relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Wildfire Conditions-Data Center-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Data Center/Rural-Owner/Occupier-v2</td>
                            <td>Data Center - Owner/Occupier</td>
                            <td>200150</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Data Center - Owner/Occupier asset type, and you should expect to
                                see wildfire risk for Data Center - Owner/Occupier assets increase
                                relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Wildfire Conditions-Data Center-Tenant-v1</td>
                            <td>Wildfire Conditions-Data Center/Rural-Tenant-v2</td>
                            <td>Data Center - Tenant</td>
                            <td>200116</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Data Center - Tenant asset type, and you should expect to see
                                wildfire risk for Data Center - Tenant assets increase relative to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Wildfire Conditions-E Suite-Tenant-v1</td>
                            <td>Wildfire Conditions-E Suite-Rural-Tenant-v2</td>
                            <td>Executive Suite - Tenant</td>
                            <td>200206</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Executive Suite - Tenant asset type, and you should expect to see
                                wildfire risk for Executive Suite - Tenant assets increase relative
                                to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Wildfire Conditions-Retail-Tenant-v1</td>
                            <td>Wildfire Conditions-Retail-Rural-Tenant-v2</td>
                            <td>Retail - Tenant</td>
                            <td>200152</td>
                            <td>
                                Version 1 of this impact function only includes the Employee Health
                                Pathway. Version 2 of this impact function also includes Business
                                Interruption and Physical Damage impact pathways.
                            </td>
                            <td>
                                Because two additional impact pathways have been added, this model
                                now shows increased vulnerability to wildfire conditions for the
                                Retail - Tenant asset type, and you should expect to see wildfire
                                risk for Retail - Tenant assets increase relative to previous
                                values.
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Wildfire Conditions-Paper Product Mills/Rural-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Paper Product Mills/Rural-Owner/Occupier-v2</td>
                            <td>Paper Product Mills</td>
                            <td>200407</td>
                            <td>
                                The underlying assumption for the likelihood of direct impact for
                                rural areas has been adjusted for the Business Interruption and
                                Physical Damage impact pathways.
                            </td>
                            <td>
                                Because the underlying assumption for the likelihood of direct
                                impact for rural areas has been updated, this model now shows
                                decreased vulnerability to wildfire conditions for the Paper Product
                                Mills asset type, and you should expect to see wildfire risk for
                                Paper Product Mill assets decrease relative to previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Wildfire Conditions-Paper Mill (1) - Rural-Owner/Occupier-v1</td>
                            <td>Wildfire Conditions-Paper Mill (1) - Rural-Owner/Occupier-v2</td>
                            <td>
                                1. Paper Mill (1) - Owner/Occupier (High Withdrawal)
                                <br />
                                2. Paper Mill (1) - Owner/Occupier (Low Withdrawal)
                                <br />
                                3. Paper Mill (1) - Owner/Occupier (Medium Withdrawal)
                            </td>
                            <td>
                                200113
                                <br />
                                200115
                                <br />
                                200114
                            </td>
                            <td>
                                The underlying assumption for the likelihood of direct impact for
                                rural areas has been adjusted for the Business Interruption and
                                Physical Damage impact pathways.
                            </td>
                            <td>
                                Because the underlying assumption for the likelihood of direct
                                impact for rural areas has been updated, this model now shows
                                decreased vulnerability to wildfire conditions for the Paper Mill
                                (Owner/Occupier) asset type, and you should expect to see wildfire
                                risk for Paper Mill (Owner/Occupier) assets decrease relative to
                                previous values.
                            </td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Avg Daily Max Temp Chg-Light Manufacturing-Owner/Occupier-v1</td>
                            <td>Avg Daily Max Temp Chg-Light Manufacturing-Owner/Occupier-v2</td>
                            <td>Light Manufacturing - Owner/Occupier</td>
                            <td>200462</td>
                            <td>
                                The financial ratios used to construct cooling cost pathway was
                                adjusted to correct a previous value.
                            </td>
                            <td>
                                Because the underlying financial ratio for the cooling cost impact
                                pathway has been updated, this model now shows increased
                                vulnerability to extreme temperature for the Light Manufacturing -
                                Owner/Occupier asset type, and you should expect to see extreme
                                temperature risk for Light Manufacturing - Owner/Occupier assets
                                increase relative to previous values.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p className="spg-text spg-text-paragraph">
                    The changes will go live in Climanomics on the week of June 24, 2024. If you
                    would like to have a record of your risk values prior to the updates, we suggest
                    exporting your risk outputs prior to June 24th. If you have questions or need
                    assistance, please reach out to your Relationship Manager or to
                    Climanomics_Support@spglobal.com.
                </p>

                <Divider />

                <h3 className="spg-heading spg-heading--medium spg-mt-md">May 2, 2024</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Full Suite of Adaptation Impact Functions Now Available
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to share the release of our full suite of impact functions with
                    adaptation measures in our effort to integrate climate adaptation and mitigation
                    measures in our climate risk models.
                    <br />
                    <br />
                    As of May 2024, we have a total of 10 impact functions with adaptation measures,
                    applied to 24 asset types (as relevant) that are mostly commonly used in
                    Climanomics. As a Climanomics user, you can now select any of these asset types
                    and assign to your assets from the Asset Type Library (navigate to "Manage" on
                    the sidebar, then click "Asset Types").
                </p>

                <p className="spg-text spg-text-paragraph">
                    <br />
                    The following adaptation measures have been integrated in our impact function
                    models:
                    <br />
                    <br />
                    <table className="spg-table">
                        <thead>
                            <tr>
                                <th className="spg-p-md">Physical Hazard</th>
                                <th className="spg-p-md">Adaptation Measures </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Flooding (Coastal, Pluvial, Fluvial)</td>
                                <td>
                                    <ul>
                                        <li>Floodwalls</li>
                                        <li>Wet Flood-Proofing</li>
                                        <li>Elevating Structures</li>
                                        <li>Dry Flood-Proofing</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Extreme Temperatures (Heat)</td>
                                <td>
                                    <ul>
                                        <li>Cool Roofs</li>
                                        <li>Green Roofs</li>
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td>Tropical Cyclone</td>
                                <td>
                                    <ul>
                                        <li>
                                            Impact-resistant glass window & reinforced concrete
                                            foundation
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Water Stress</td>
                                <td>
                                    <ul>
                                        <li>Rainwater Harvesting</li>
                                        <li>Greywater Recycling</li>
                                        <li>
                                            Rainwater Harvesting and Greywater Recycling (Combined)
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </p>
                <p className="spg-text spg-text-paragraph">
                    <br />
                    The impact functions have been applied to the following asset types, as
                    relevant:
                    <ol type="1">
                        <li>Single Family Housing - Owner/Occupier</li>
                        <li>Multifamily Housing - Owner/Occupier (Low-Rise or Garden Style)</li>
                        <li>Multifamily Housing - Investor (Low-Rise or Garden Style)</li>
                        <li>Multifamily Housing - Investor (Mid or High-Rise)</li>
                        <li>Office - Owner/Occupier</li>
                        <li>Office - Tenant</li>
                        <li>Office (Mid & High-Rise) - Investor</li>
                        <li>Retail - Owner/Occupier</li>
                        <li>Retail - Investor </li>
                        <li>Retail - Tenant</li>
                        <li>Shopping Center - Investor </li>
                        <li>Industrial and Logistics - Owner/Occupier </li>
                        <li>Data Center - Owner/Occupier</li>
                        <li>Hotel - Owner/Occupier </li>
                        <li>Warehouse - Owner/Occupier</li>
                        <li>Warehouse - Tenant</li>
                        <li>Warehouse - Investor</li>
                        <li>Condominium - Owner/Occupier (Upper Story)</li>
                        <li>Condominium - Owner/Occupier (Ground Floor)</li>
                        <li>Light Manufacturing - Owner/Occupier</li>
                        <li>Electricity Transmission Tower - Owner/Operator</li>
                        <li>Aboveground Pipeline - Owner/Operator</li>
                        <li>Cell Tower Site - Owner/Operator</li>
                        <li>Product Terminal - Owner/Operator</li>
                    </ol>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Adaptation Definitions:
                    <br />
                    <br />
                    <ul className="spg-list">
                        <li>
                            <strong>Floodwalls:</strong> Floodwalls are vertical structures anchored
                            into the ground that are designed to withstand flooding from either
                            rivers or storm surge and prevent areas behind the wall from flooding.
                            Floodwalls are walls built along a shoreline or bank to protect from
                            flooding. These engineered structures temporarily constrain the waters
                            of a river or other waterway as it rises due to extreme weather events.
                            Floodwalls sometimes have gates to allow access for a roadway or other
                            right-of-way, which can be closed in advance of a flood event (Federal
                            Emergency Management Agency, 2012).
                        </li>
                        <li>
                            <strong>Wet Flood-Proofing:</strong> Wet flood-proofing involves use of
                            flood damage resistant materials that do not need to be replaced if
                            flooded, including pressure-treated plywood, concrete, and cement board.
                            Flood vents are installed in the walls of the enclosure to let flood
                            waters enter and leave by gravity, which allows forces on either side of
                            the structure’s walls to equalize. This prevents the structure and
                            foundation from collapsing in the event of a flood (Federal Emergency
                            Management Agency, 2012).
                        </li>
                        <li>
                            <strong>Elevating Structures​:</strong> Elevating a building to prevent
                            floodwaters from reaching living areas is an effective retrofitting
                            method. The goal of the elevation process is to raise the lowest floor
                            to or above the flood protection elevation (FPE). This can be done by
                            elevating the entire building, including the floor, or by leaving the
                            building in its existing position and constructing a new, elevated floor
                            within the building. The method used depends largely on construction
                            type, foundation type, and flooding conditions (Mile High Flood
                            District, 2016; Federal Emergency Management Agency, 2012).
                        </li>
                        <li>
                            <strong>Dry Flood-Proofing:</strong> ​Dry flood-proofing includes
                            measures that make a structure watertight below the level that needs
                            flood protection to prevent floodwaters from entering. It involves
                            sealing the exterior of a building to prevent the entry of flood waters.
                            This technique can only be used when the walls are strong enough to
                            withstand the hydrostatic force of the water. Shields may be installed
                            to seal off doors, windows, and other openings (STC Planning, 2019).
                        </li>
                        <li>
                            <strong>Cool Roofs:</strong> Cool roofs are made up of highly reflective
                            and emissive materials that can keep roofs 50 to 60 degrees cooler than
                            roofs made from traditional materials during warmer days. They can be
                            made from a variety of different light-colored materials including
                            coatings, asphalt shingles, metal, clay tiles, and concrete tiles, all
                            known to reflect off heat. As a building adaptation, cool roofs can
                            reduce cooling costs by decreasing air conditioning needs and
                            subsequently reducing energy bills (Environmental Protection Agency,
                            2022 a).
                        </li>
                        <li>
                            <strong>Green Roofs:</strong> Green roofs, roofs inclusive of a
                            vegetative overgrown layer, are a type of cool roof that can keep
                            buildings cooler during the summer and warmer during the winter. Green
                            roofs provide more energy savings than cool roofs as they act as an
                            additional barrier between a building’s interior and exterior
                            environment, both hot and cold (Environmental Protection Agency, 2022
                            b).
                        </li>
                        <li>
                            <strong>Rainwater Harvesting:</strong> During the rainy season, a rain
                            harvesting system (water tank design) is used to catch and collect
                            rainwater. The rain harvesting system design uses gravity for water
                            flow, reducing the electricity that would otherwise be needed for
                            pumping. This water is then available for people to use and consume
                            during the dry season when there is a shortage of clean water. Likewise,
                            a rain harvesting system (absorption well design) can also capture
                            rainwater and funnel it directly into the ground in order to conserve
                            ground water in the future (United Nations Climate Change, n.d.).
                        </li>

                        <li>
                            <strong>Greywater Recycling:</strong> Greywater recycling capture water
                            from showers, washing machines, bathroom and kitchen sinks. Greywater
                            does not include water from toilets or wash water with fecal material
                            (e.g. soiled diapers) (McCormick and Marshall, 2015).
                        </li>
                    </ul>
                    <br />
                    For further questions about this release, please contact your Relationship
                    Manager or{' '}
                    <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                        Climanomics Support
                    </a>{' '}
                    ​​​​​​
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">February 7, 2024</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Client Portfolios Rescored with Updated Hazard Data
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We have completed the rescore of all client portfolios with the enhanced hazard
                    data. The risk values displayed in the platform as well as in the data exports
                    and summary reports are now based on updated data for Wildfire, Drought and
                    Fluvial Flooding, as well as data additions and corrections for Coastal Flooding
                    and Water Stress.
                    <br />
                    <br />
                    For an overview of the hazard enhancements, please refer to the December 4, 2023
                    release note below. Moreover, the{' '}
                    <a
                        className="spg-link"
                        onClick={handleDocumentClick}
                        download="Climanomics_Methodology_22Aug_2024.pdf"
                    >
                        climate risk modeling methodology
                    </a>{' '}
                    has been updated to reflect the details of all the enhancements.
                    <br />
                    <br />
                    If you would like access to the risk values of your portfolio prior to this data
                    enhancement, please reach out to your Relationship Manager.
                </p>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Hazard Metrics and Disaggregated MAAL Exports
                </h1>
                <p className="spg-text spg-text-paragraph">
                    To help you contextualize risk as well as map the financial impacts of climate
                    risk to balance sheets, we have made asset-level hazard data including the
                    baseline values, as well as the disaggregated Modeled Average Annual Loss (MAAL)
                    or percentage at risk by impact pathway, easily accessible in Climanomics. These
                    data are now exportable from the Climanomics data export builder page.
                    <br />
                    <br />
                    <Image src={image28} style={{ width: '60rem', height: 'auto' }} />
                    These outputs will be in CSV format. Each output have a significant amount of
                    data, hence, report generation may take longer, although you are able to track
                    the status of the report generation in the export dashboard.
                </p>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Adaptation Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are starting to integrate adaptation and mitigation into our climate risk
                    analysis by developing new impact functions with adaptation measures. By
                    integrating adaptation, Climanomics is able to offer a more precise analysis of
                    an asset, and meets the needs of clients with real assets that have built-in
                    adaptation measures.
                    <br />
                    <br />
                    The first batch of impact functions with adaptation measures are now available
                    in Climanomics. This batch consists of Impact Functions related to Coastal,
                    Pluvial and Flooding Flooding and have been assigned to the 23 most commonly
                    used asset types in Climanomics. You will find the list of new impact functions
                    and asset types with their descriptions in the{' '}
                    <a className="spg-link" href="/impact-functions">
                        Impact Function Library
                    </a>{' '}
                    and in the{' '}
                    <a className="spg-link" href="/asset-types">
                        Asset Type Library
                    </a>
                    .
                    <br />
                    <br />
                    The second batch of impact functions will be added to Climanomics on a rolling
                    basis in Q1 of 2024. These impact functions relate to Extreme Temperature
                    (Heat), Tropical Cyclone and Water Stress.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">December 11, 2023</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Hazard Enhancements Released
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We have released the latest hazard data enhancements. For more details on each
                    enhancement, please see the release notes dated on December 4, 2023. These
                    enhancements may lead to a change in risk values, but the change will only be
                    reflected in individual portfolios after portfolios are rescored. The rescore
                    process will take place within the next two weeks. Please note that due to the
                    large volume of assets that are being scored, you may experience slower scoring
                    speeds in Climanomics in the next two weeks. If you experience a full
                    disruption, please contact{' '}
                    <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                        Climanomics Support
                    </a>{' '}
                    or your Relationship Manager.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">December 4, 2023</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Upcoming Hazard Data Enhancements
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Over the last three months, we have been working on significant updates to our
                    physical hazard models in our continuing effort to provide clients with the most
                    precise and actionable climate risk analysis. We are releasing these updates in
                    Climanomics on December 11, 2023. There is no scheduled downtime for the
                    production release of the updated hazard data, but you may experience
                    intermittent performance of the Climanomics platform between 8:00 - 11:30 AM
                    Eastern Time due to the large volume of hazard data being ingested in the
                    Climanomics database. If you experience a full disruption, please contact{' '}
                    <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                        Climanomics Support
                    </a>{' '}
                    or your Relationship Manager.
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    What are the hazard updates?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    <br />
                    The following hazard enhancements will be implemented as of December 11, 2023:
                    <br />
                    <br />
                    <table className="spg-table">
                        <thead>
                            <tr>
                                <th className="spg-p-md">Hazard</th>
                                <th className="spg-p-md">Enhancement</th>
                                <th className="spg-p-md">Benefit to Users</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Coastal Flooding</td>
                                <td>
                                    Addition of coastal flood hazard data for missing countries,
                                    such as The Maldives.
                                </td>
                                <td>Increased coastal flood coverage.</td>
                            </tr>
                            <tr>
                                <td>Drought*</td>
                                <td>
                                    Transition from PDSI (Palmer Drought Severity Index) to SPEI
                                    (Standardized Precipitation Evapo-transpiration Index)-based
                                    modeling.
                                    <br />
                                    The SPEI is a multiscalar drought index capable of determining
                                    the onset, duration and magnitude of short and long term drought
                                    conditions.
                                    <br />
                                    The calculation of exposure scores will also transition from the
                                    use of a relative hazard metric (change relative to local
                                    baseline conditions) to an absolute hazard metric à fraction of
                                    months in severe drought conditions or above (as defined by the
                                    SPEI).
                                </td>
                                <td>
                                    <ul className="spg-list">
                                        <li>
                                            Enhanced modelling of drought risk in the context of
                                            climate change.
                                        </li>
                                        <li>More intuitive drought exposure scores.</li>
                                        <li>Coverage of all on-land locations globally.</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Fluvial Flooding</td>
                                <td>
                                    Integration of baseline and projected fluvial flood extent at
                                    1x1 km resolution from the previous basin scale. This resolution
                                    captures both fluvial flood risks to the point asset location
                                    and to other areas and infrastructure in the immediate vicinity.
                                    <br />
                                    <em>Data source: WRI Aqueduct Floods dataset</em>
                                </td>
                                <td>
                                    <ul className="spg-list">
                                        <li>
                                            Higher resolution analysis of fluvial flood risks at
                                            asset locations and in surrounding areas.
                                        </li>
                                        <li>
                                            Reduced overestimation of fluvial flood hazard within
                                            high fluvial hazard basins but at high elevation
                                            locations or locations distant from rivers.
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Water Stress</td>
                                <td>Data correction.</td>
                                <td>Improved precision of risk values.</td>
                            </tr>
                            <tr>
                                <td>Wildfire*</td>
                                <td>
                                    Transition from Z-Index to FWI (Fire Weather Index)-based
                                    wildfire conditions modeling, combined with high resolution
                                    (300x300m) land cover adjustments.
                                    <br />
                                    The FWI is a meteorologically based index used worldwide to
                                    estimate fire danger. It consists of different components that
                                    account for the effects of fuel moisture and wind on fire
                                    behavior and spread. High FWI values indicate more favorable
                                    conditions for the ignition and spread of wildfire.
                                    <br />A land cover mask is applied to differentiate areas
                                    containing (or adjacent to) burnable vegetation.{' '}
                                </td>
                                <td>
                                    <ul className="spg-list">
                                        <li>
                                            High resolution modelling of changes in wildfire
                                            conditions due to climate change and the presence of
                                            burnable vegetation surrounding an asset.
                                        </li>
                                        <li>
                                            Adoption of a wildfire conditions metric used widely by
                                            governments and in academia.
                                        </li>
                                        <li>Coverage of all on-land locations globally.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="spg-text spg-text-paragraph">
                        <em>
                            *Detailed technical reports are available upon request. Please contact
                            your Relationship Manager.
                        </em>
                    </p>
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    When will I see the updates in my portfolio of assets?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    You will see updated risk values based on updated hazard data after your
                    portfolio is rescored. We will start rescoring client portfolios also on
                    December 11th. Due to the large volume of assets for rescore, portfolios will be
                    rescored on a rollling basis over a three-week period starting December 11th.
                    You will be notified when your portfolio has completed rescoring.
                    <br />
                    <br />
                    When rescored, your portfolio will also show Transition Risk values. We
                    re-implemented the Transition Risk methodology in Climanomics in October 2023 to
                    support voluntary reporting requirements. Transition Risk was rolled out in
                    phases as well and will be fully rolled out to all portfolios after this rescore
                    process. Please note that the Transition Risk methodology in Climanomics
                    continues to be under review and we are looking to update it in 2024.
                </p>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">Platform Enhancements</h1>
                <p className="spg-text spg-text-paragraph">
                    Earlier in November, we launched a new report builder that allows users to
                    automatically generate climate risk analytics reports in Climanomics. The
                    Summary Report builder has the following capabilities:
                    <ul className="spg-list">
                        <li>
                            Users can create a report for an entire portfolio, a folder of assets,
                            or a single asset.
                        </li>
                        <li>
                            A report can be generated for Physical Risk, Transition Risk or both.
                        </li>
                        <li>
                            A report can be for 4 climate scenarios or a selection of scenarios.
                        </li>
                        <li>
                            One report for one decade. A new report will have to be generated for
                            each decade.
                        </li>
                        <li>
                            A user's ability to generate and download summary reports depends on the
                            user's role and permissions:
                        </li>
                        <ul className="spg-list">
                            <li>
                                Client Admin: can generate reports for the entire portfolio, any
                                folder or any asset
                            </li>
                            <li>
                                Client Editor: can generate a report for permissioned folders or
                                assets
                            </li>
                            <li>Client Viewer: cannot generate a summary report </li>
                        </ul>
                        <li>
                            The report is in PPTX format and the template is customizable. Output
                            charts and graphs are, however, not customizable.{' '}
                        </li>
                    </ul>
                    To access the new report builder, click on the main menu to see the sidebar. On
                    the sidebar, click the Summary Reports link.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">October 13, 2023</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Climanomics has a new look
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to welcome you to the redesigned Climanomics. As part of the
                    Climanomics integration into S&P Global, our team has been working to reskin the
                    Climanomics pages to give them the look and feel of an S&P Global product, as
                    well as provide you a seamless experience as you navigate between Climanomics
                    and other S&P applications.
                </p>
                <p className="spg-text spg-text-paragraph">Highlights of the redesign:</p>
                <ul className="spg-list">
                    <li>
                        There are no changes to the functionalities in Climanomics. Changes are
                        limited to the design.
                    </li>
                    <li>
                        You can switch between the default light theme and dark mode. To switch
                        themes, go to your profile and click the toggle button.
                    </li>
                </ul>
                <Image src={image27} />
                <p className="spg-text spg-text-paragraph">
                    In addition, we have integrated Transition Risk in Climanomics to provide
                    clients the ability to incorporate Transition Risk in their TCFD reporting and
                    other activities. Climanomics will calculate risk outputs for the following
                    Transition Risk factors:
                </p>
                <ul className="spg-list">
                    <li>
                        <strong>Carbon Pricing:</strong> Related to policies and regulations that
                        may impose a carbon price through such mechanisms as carbon taxes or
                        emissions trading. Climanomics utilizes carbon price projections from the
                        underlying data of the Shared Socioeconomic Pathways (SSPs) models used by
                        the IPCC. More details of Carbon Pricing modeling will be incorporated in
                        the methodology document in the coming weeks.
                    </li>
                    <li>
                        <strong>Litigation:</strong> Corporations face increasing costs to defend
                        against climate-related litigation. Claims include failure to mitigate,
                        adapt, and disclose risks in reference to various local and sovereign laws
                        and regulations. At present, in the absence of robust litigation-risk data,
                        Climanomics uses temperature extremes to provide a forcing function for
                        future litigation risks, with the assumption of proportionality between
                        legal and regulatory actions. Climanomics applies the localized frequency of
                        daily maximum temperature above the 90th percentile, as compared to the
                        baseline period (1950-1999). This is coupled with impact functions
                        reflecting high, medium, and low levels of litigation impacts.
                    </li>
                    <li>
                        <strong>Reputation:</strong> Perception of an organization's "social license
                        to operate" can affect supplier prices, employee costs, consumer demand, and
                        shareholder value. Multiple stakeholders should be considered during an
                        analysis of reputational risk. At present, in the absence of robust
                        reputation-risk data, Climanomics uses temperature extremes to provide a
                        forcing function for future reputational risks. Climanomics applies the
                        localized frequency of daily maximum temperature above the 90th percentile,
                        as compared to the baseline period (1950-1999).
                    </li>
                    <li>
                        <strong>Technology:</strong> In the transition to a lower-carbon economy,
                        technological advancements may cause financial impacts. To the extent that
                        new technologies reduce competitiveness, production efficiency, or demand,
                        they can lead to impaired or stranded assets. At present, in the absence of
                        robust technology-risk data, Climanomics uses temperature extremes to
                        provide a forcing function for future technology risks. Climanomics applies
                        the localized frequency of daily maximum temperature above the 90th
                        percentile, as compared to the baseline period (1950-1999).
                    </li>
                    <li>
                        <strong>Market:</strong> In the transition to a lower-carbon economy, both
                        supply and demand for products and services can be affected. At present, in
                        the absence of standardized data on how markets may respond in the face of
                        such a far-reaching change, Climanomics uses temperature extremes to provide
                        a forcing function for future market risks. Climanomics applies the
                        localized frequency of daily maximum temperature above the 90th percentile,
                        as compared to the baseline period (1950-1999).
                    </li>
                    <p className="spg-text spg-text-paragraph">
                        All assets created or uploaded in Climanomics after October 13 will include
                        Transition Risk values for these five risk factors. Transition Risk for
                        existing client portfolios will be calculated on a rolling basis in
                        November.
                    </p>

                    <p>
                        For questions about these releases, please contact{' '}
                        <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                            Climanomics Support
                        </a>
                        .
                    </p>
                </ul>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">September 14, 2023</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Climanomics API and new asset types now available
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to announce the release of the Climanomics API for single assets,
                    as well the availability of 25 new asset types.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">Climanomics API</h2>
                <p className="spg-text spg-text-paragraph">
                    The new Climanomics API enables clients to retrieve the physical risk values of
                    a single asset. This Rest API is ideal for clients conducting time-sensitive due
                    diligence of an asset. Below is a summary of the API specifications:
                </p>
                <ul className="spg-list">
                    <li>One asset per API call.</li>
                    <li>Request parameters:</li>
                    <ul className="spg-list">
                        <li>Asset Name</li>
                        <li>Asset reference ID</li>
                        <li>Asset Value</li>
                        <li>Latitude</li>
                        <li>Longitude</li>
                        <li>Asset Type ID</li>
                    </ul>
                    <li>Response outputs:</li>
                    <ul className="spg-list">
                        <li>Asset reference ID</li>
                        <li>Asset name</li>
                        <li>Asset type</li>
                        <li>Asset value</li>
                        <li>Latitude</li>
                        <li>Longitude</li>
                        <li>Country</li>
                        <li>Scenario</li>
                        <li>Risk Factor</li>
                        <li>Pct Risk (2020 to 2090)</li>
                        <li>MAAL (2020 to 2090)</li>
                    </ul>
                    <li>Output format: JSON</li>
                </ul>
                <p className="spg-text spg-text-paragraph">
                    If you are interested in this capability, please contact your Relationship
                    Manager.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">New Asset Types</h2>
                <p className="spg-text spg-text-paragraph">
                    We have introduced 25 new asset types as part of our effort to provide more
                    relevant climate risk data for your holdings. The new asset types are now
                    available to be assigned to your assets. View the list of new asset types{' '}
                    <a
                        className="spg-link"
                        href="https://spgl.sharepoint.com/:w:/r/sites/group_nam_corp_esg_tcs_m/_layouts/15/Doc.aspx?sourcedoc=%7B206FFC34-7517-44DD-A1F6-2E1E3B2138DC%7D&file=New%20Asset%20Types%20September%202023.docx&action=default&mobileredirect=true"
                    >
                        here.
                    </a>
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Change in the Data Import Template
                </h2>
                <p className="spg-text spg-text-paragraph">
                    As part of ongoing efforts to improve the import of assets with multiple
                    locations, we are updating the CSV template that is used to upload assets in
                    bulk. The new template is planned to go live on September 28. With the new
                    template, the old template will no longer be usable and uploading asset data
                    using the old template will result in an error. Please download the updated
                    template from the Import page if you are uploading assets in bulk on or after
                    September 28.
                    <br></br>
                    <br></br>
                    The change in the template will be the addition of the Location Reference ID as
                    the identifier for locations that are associated with an asset. If you have an
                    asset with more than one location, we recommend that you add a Location
                    Reference ID to distinguish the different locations. If this field is left
                    blank, Climanomics will automatically generate a Location Reference ID. The
                    multiple locations will display as "Location 1", "Location 2", etc. on the
                    individual asset profile page.
                    <br></br>
                    <br></br>
                    As a result, Climanomics will now ingest duplicate Asset Reference IDs as long
                    as they are linked to assets with more than one locations. Duplicate Asset
                    Reference IDs for single location assets will still generate an error.
                    <br></br>
                    <p>
                        Please reach out to{' '}
                        <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                            Climanomics Support{' '}
                        </a>
                        if you have any questions or need assistance.{' '}
                    </p>
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">August 16, 2023 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Disaster recovery testing scheduled for August 16 and 26; service disruption
                    expected
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Climanomics will undergo scheduled maintenance on August 16 and 26 primarily for
                    disaster recovery testing of the platform. This will test the platform&apos;s
                    resilience to disasters and validate its disaster recover capabilities.
                </p>
                <p className="spg-text spg-text-paragraph">
                    First maintenance schedule: <br />
                    <strong>Start Date:</strong> Saturday, August 19, 2:00 AM EDT (GMT-5) <br />
                    <strong>End Date:</strong> Sunday, August 20, 2:00 AM EDT (GMT-5)
                </p>
                <p className="spg-text spg-text-paragraph">
                    Second maintenance schedule: <br />
                    <strong>Start Date:</strong> Saturday, August 26, 2:00 AM EDT (GMT-5) <br />
                    <strong>End Date:</strong> Sunday, August 27, 2:00 AM EDT (GMT-5)
                </p>
                <p className="spg-text spg-text-paragraph">
                    Climanomics will be inaccessible to all users during these maintenance windows.
                    In addition, risk scoring of assets, bulk asset uploads and exports will be
                    suspended. We apologize for any inconvenience this may cause.
                </p>
                <p className="spg-text spg-text-paragraph">
                    If you have any questions, please contact your dedicated Relationship Manager or
                    the{' '}
                    <a className="spg-link" href="mailto:Climanomics_Support@spglobal.com">
                        Climanomics support team
                    </a>
                    .
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">June 28, 2023 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Climate risk modeling and platform upgrades now available
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are pleased to announce the release of significant updates to our physical
                    climate risk modeling methodology in Climanomics as well as platform
                    enhancements that will optimize your workflows.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Notable enhancements include the application of the CMIP6 hazard modeling to our
                    models, increasing the coverage of Tropical Cyclone, and strengthening our
                    Coastal Flooding model, among others. This release aligns the methodology for
                    calculating the financial impact of physical climate risk of real assets with
                    our Physical Risk dataset currently available in Xpressfeed.
                </p>
                <p className="spg-text spg-text-paragraph">
                    We are also introducing Pluvial Flooding risk, or the risk from extreme
                    precipitation. With this new hazard, Climanomics now calculates risk for eight
                    climate hazards, making it one of the most comprehensive climate risk platforms
                    in the industry.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Along with enhancements to our climate modeling, we are releasing features that
                    improve platform usability, streamline self-service workflows, and increase the
                    rate by which Climanomics scores your assets. These improvements will improve
                    user experience, and ensure that clients are able to derive the most value from
                    the platform.
                </p>
                <p className="spg-text spg-text-paragraph">
                    This release excludes Transition Risk and Opportunities. We are in the process
                    of integrating the CMIP6 hazard data into the existing Transition Risk and
                    Opportunities models, and we are also taking the opportunity to review and
                    explore areas to improve for these two analysis types.
                </p>
                <p className="spg-text spg-text-paragraph">
                    In addition, fluvial hazard outputs in some regions and climate scenarios have
                    elevated levels of uncertainty due to a data source error. This issue largely
                    affects coastal locations in North America, Northern Europe and the Southern
                    Hemisphere. We will implement a modification to our fluvial flooding model to
                    correct the impact of the error in the next methodology update.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Methodology and Data Updates
                </h2>
                <ul className="spg-list">
                    <li>
                        Adoption of an enhanced climate change hazard modelling methodology based on
                        CMIP6 general circulation models and with resolution enhancements for most
                        hazards; namely coastal flooding, wildfire and tropical cyclone.
                    </li>
                    <li>
                        Addition of one new climate change hazard: Pluvial (extreme precipitation).
                    </li>
                    <li>
                        In the CMIP6 framework, a complementary set of scenarios focused on
                        projecting socioeconomic changes was developed to be used alongside the
                        RCPs. The SSPs and RCPs are combined to create a final set of scenarios for
                        input into the CMIP6 models. Combined four CMIP6 Shared Socioeconomic
                        Pathway (SSP) / Representative Concentration Pathway (RCP) hazard scenarios
                        are as follows:
                    </li>
                    <ol className="spg-list">
                        <li>
                            High Climate Change Scenario (SSP5-8.5): Low mitigation scenario in
                            which total greenhouse gas emissions triple by 2075 and global average
                            temperatures rise by 3.3-5.7C by 2100.
                        </li>
                        <li>
                            Moderate-High Climate Change Scenario (SSP3-7.0): Limited mitigation
                            scenario in which total greenhouse gas emissions double by 2100 and
                            global average temperatures rise by 2.8-4.6C by 2100.
                        </li>
                        <li>
                            Moderate Climate Change Scenario (SSP2-4.5): Strong mitigation scenario
                            in which total greenhouse gas emissions stabilize at current levels
                            until 2050 and then decline to 2100. This scenario is expected to result
                            in global average temperatures rising by 2.1-3.5C by 2100.
                        </li>
                        <li>
                            Low Climate Change Scenario (SSP1-2.6): Aggressive mitigation scenario
                            in which total greenhouse gas emission reduce to net zero by 2050,
                            resulting in global average temperatures rising by 1.3-2.4C by 2100,
                            consistent with the goals of the Paris Agreement.
                        </li>
                    </ol>
                </ul>
                <p className="spg-text spg-text-paragraph">
                    These methodological updates reflect the continued evolution of climate science
                    models and our commitment to deliver outputs using the most advanced climate
                    modeling techniques.
                </p>
                <p className="spg-text spg-text-paragraph">
                    You can access a more detailed description of the enhanced methodologies in the{' '}
                    <a
                        className="spg-link"
                        onClick={handleDocumentClick}
                        download="Climanomics_Methodology_22Aug_2024.pdf"
                    >
                        Methodology page.
                    </a>
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Climanomics Platform Enhancements:
                </h2>
                <ul className="spg-list">
                    <li>
                        <strong>Display features of assets: </strong> We have implemented advanced
                        display features and you can now readily view your portfolio of assets on
                        the main page, instead of having to drill down through asset folders to get
                        to a single asset.
                    </li>
                    <li>
                        <strong>Improved searchability of assets: </strong> You can now search for
                        an asset using the search bar. Currently the search parameter is “asset
                        name” but there are planned new enhancements to include other asset
                        identifiers.
                    </li>
                    <li>
                        <strong>Flexible asset groupings: </strong>You can benefit from added
                        flexibility of asset folder structure by being able to organize your assets
                        in a way that is most relevant to your business. You now have the
                        flexibility to create and edit your own folders, as well as the groupings of
                        folders under the “All Asset” page in the “Manage” section of Climanomics®.
                    </li>
                    <li>
                        <strong>Improved bulk import features: </strong>You can now upload up to 1
                        million asset records in one file regardless of asset type. You no longer
                        need to split your upload file by asset type; instead you can upload all
                        assets with different asset types in one file. File formats of the upload
                        file is now switched to CSV from Excel for easier data manipulation.
                        Moreover, the platform now features an import tracking dashboard from you
                        can track the scoring status of the assets that have been uploaded. To bulk
                        upload your assets, go to the “Manage” section of Climanomics® then click
                        on the “Import” tab on the sidebar.
                    </li>
                    <li>
                        <strong>A new export builder: </strong>You are now able to create your own
                        exports based on the parameters that are most relevant for your work. You
                        can export up to 10,000 records in a single file. The platform has an added
                        functionality of an export tracking dashboard from which you can track the
                        export process and then download the report when it is ready. To export data
                        from Climanomics, go to the “Manage” section of Climanomics®, then click on
                        the “Export” tab on the sidebar.
                    </li>
                    <li>
                        <strong>Enhanced security and privacy features: </strong> You can now
                        leverage role-based access to enhance the security and privacy of your
                        assets. Each client organization will have its own Administrator who will
                        have control over the permissions of the users within the organization.
                        Users’ ability to create, edit or delete assets, as well as bulk upload and
                        export will depend on their permissions as determined by their
                        administrator.
                    </li>
                    <li>
                        <strong>Improved platform performance: </strong> Climanomics® users will
                        experience a notable progress in the performance of the platform and this
                        includes faster scoring of your assets and improved page loading times.
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">
                    Please reach out to your Relationship Manager or
                    Climanomics_Support@spglobal.com if you need training on the new features in
                    Climanomics®.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Ongoing Review of Transition Risk and Opportunities{' '}
                </h2>
                <p className="spg-text spg-text-paragraph">
                    This release does not include Transition Risk and Opportunities. As an existing
                    Climanomics® subscriber, you will see Physical Risk values for your portfolio
                    of assets that have been rescored based on the updated climate modeling
                    methodologies. You will not be able to see or export Transition Risk and
                    Opportunities data. The timeline for implementation of these two analysis types
                    will be communicated to you.
                </p>
                <p className="spg-text spg-text-paragraph">
                    You will, however, have access to your historical Transition Risk and
                    Opportunities outputs in a data format. Please contact your Relationship Manager
                    for your historical data sets.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Elevated Uncertainty in Fluvial Flooding Hazard Outputs{' '}
                </h2>
                <p className="spg-text spg-text-paragraph">
                    We have identified an error in one of the data sources used to calculate the
                    fluvial hazard output in some regions and climate scenarios. The downscaled
                    CMIP6 climate model data for the variable “Tasmin” (minimum daily temperature)
                    are erroneous in some coastal regions for the two climate scenarios Low-SSP126
                    and Medium-High-SSP370. Tasmin is used to determine local annual frost day
                    counts, which, in turn, is one of several inputs to the fluvial hazard model.
                    The errors in Tasmin data were confirmed by the data vendor and will be resolved
                    in due course. We will modify our fluvial flooding model to correct the impact
                    of the erroneous Tasmin data. The resulting fluvial hazard output will be
                    insensitive to the erroneous Tasmin, and the decade-to-decade variation will be
                    smoother, better reflecting the impact of large-scale climate change.{' '}
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">What to Expect </h2>
                <p className="spg-text spg-text-paragraph">
                    When you onboard to the upgraded platform, you will see your portfolio of assets
                    rescored based on the CMIP6 methodology and other model updates. Due to the
                    methodology change, the risk outputs of your portfolio may be different from the
                    risk outputs in the old Climanomics. The changes in risk values for hazards such
                    as Coastal Flooding and Tropical Cyclone may be more pronounced due to the
                    significant model enhancements to these two hazards. Please reach out to your
                    Relationship Manager for more details on how the new methodologies might have
                    changed your portfolio’s risk results.{' '}
                </p>
                <p className="spg-text spg-text-paragraph">
                    In order to facilitate a smooth transition, we have stored your historical risk
                    values and will them available in data format upon request. You will then be
                    able to compare the new results with the historical risk results using the data
                    files that will be provided to you by your Relationship Manager.{' '}
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">About Climanomics</h2>
                <p className="spg-text spg-text-paragraph">
                    The S&P Global Climanomics ® platform enables users to quantify the future
                    financial impacts of climate change, integrating terabytes of data consisting of
                    eight climate change physical hazards across four scenarios and eight time
                    periods.
                </p>
                <p className="spg-text spg-text-paragraph">
                    The majority of the climate data underpinning Climanomics® is derived from the
                    Coupled Model Intercomparison Project (CMIP) run by the World Climate Research
                    Programme. Previous versions of Climanomics® were based on the fifth iteration
                    of CMIP: CMIP5. The latest version of Climanomics®, released in 2023, is based
                    on CMIP6, which integrates many of the latest advances in climate change
                    science. The CMIP6 models were developed in support of the Sixth Assessment
                    Report of the Intergovernmental Panel on Climate Change (IPCC AR6).
                    Climanomics® also leverages downscaled CMIP6 datasets provided by the NASA
                    Earth Exchange (NEX), enabling an enhancement of the resolution of analysis for
                    many hazards from 100x100km to ~25x25km spatial resolution.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Appendix: Climate Change Hazard Modelling Changes
                </h2>
                <p className="spg-text spg-text-paragraph">
                    Table 1 describes changes in the climate change hazard modelling approaches
                    utilized in the 2023 Climanomics® dataset release compared to the previous
                    release. These changes were driven primarily by the transition from CMIP5 to
                    CMIP6 as the basis for the hazard modelling, availability of improved data and
                    estimation models, and efforts to achieve consistent scenario and time period
                    coverage for all hazards.
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    Table 1 Climate Change Hazard Modelling
                </h3>
                <Image src={image25} alt="" width={802} />
                <Image
                    src={image26}
                    alt=""
                    width={803}
                    captionText="1 Water stress didn't shift to CMIP6 with the new release, since the
                        underlying data comes from WRI Aqueducts and not directly from CMIP
                        models"
                />
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Impact of Methodology and Data Updates on Climanomics&reg;
                </h2>
                <ul className="spg-list">
                    <li>
                        <strong>Coastal Flooding: </strong>
                        Coastal flooding risks indicate a declining trend, often going to 0%.
                        However, there are sporadic instances where higher intensity events occur,
                        suggesting that there are still occasional occurrences of significant
                        coastal flooding despite the declining trend. Both trends are driven by the
                        upgrades to the coastal flooding model, namely the new high resolution
                        elevation map and the addition of a flood-water path-finding algorithm.
                        These two enhancements work together to provide a significantly more
                        realistic and granular picture of which locations will actually be
                        vulnerable to coastal floodwaters and which will be protected by natural or
                        constructed topography.
                    </li>
                    <li>
                        <strong>Wildfire: </strong> The updated wildfire methodology, incorporating
                        a land use mask, has resulted in a notable change where urban assets now
                        have 0% risk of wildfire. As a result, most urban areas show a decrease in
                        wildfire risk. However, for assets located in rural regions, the pattern
                        varies based on the aridity of the area. Arid regions, such as deserts,
                        predominantly exhibit decreases in wildfire risk, while wet regions like
                        rainforests experience increases in wildfire risk.
                        <p className="spg-text spg-text-paragraph">
                            These trends can be attributed to a potential softening of CMIP6
                            predictions for changes in both extreme high and low precipitation
                            compared to CMIP5. The changes in wildfire risk observed in the updated
                            Climanomics® methodology, where arid regions experience decreased risk
                            and wet regions experience increased risk, align with climate change
                            projections. Climate change models generally indicate that currently
                            arid regions will receive less precipitation in the future, while
                            currently wet regions will receive more precipitation. These changes
                            suggest that the extreme shifts in wetness and dryness under climate
                            change, as reflected in the underlying CMIP6 data, are slightly less
                            pronounced compared to the previous CMIP5 data used in legacy
                            Climanomics®.
                        </p>
                    </li>
                    <li>
                        <strong>Tropical Cyclone: </strong>The expansion of tropical cyclone
                        coverage to the entire world in the updated methodology tends to result in
                        an increase in risk for assets located in cyclone-affected regions.
                        Previously, these assets had a risk value of 0%, but with the expanded
                        coverage, they will now be assigned a positive risk value, indicating a
                        higher level of vulnerability to tropical cyclones. In the basins that were
                        covered under legacy Climanomics®, risk values generally tend to increase
                        slightly. There are notable though moderate increases in the center of the
                        western Pacific Ocean (Taiwan, the southern Japanese islands, and the
                        northern Philippines) and moderate decreases around the Gulf of Mexico,
                        including western Florida in the US.
                    </li>
                    <li>
                        <strong>Fluvial Flooding: </strong> In general, the fluvial flooding risks
                        between the legacy and updated methodologies show minimal change. However,
                        there are a few instances where the updated methodology predicts a reduction
                        in forecasts for previously extreme areas. Notably, the Korean peninsula
                        experiences a decrease in fluvial flooding risk compared to the legacy
                        methodology.
                    </li>
                    <li>
                        <strong>Extreme Temperature: </strong>Extreme temperature risks generally
                        exhibit widespread increases, which can be attributed to the higher warming
                        projections of the CMIP6 model ensemble compared to CMIP5 across various
                        climate scenarios. However, there is an exception in South Asia, where
                        moderate decreases in temperature extremes are observed in several scenarios
                        and decades.
                    </li>
                    <li>
                        <strong>Drought: </strong>The changes in risk values of drought follow a
                        trend similar to that of changes in wildfire risk, since both are driven in
                        large part by precipitation. In arid regions, there is a decrease in drought
                        risk values, indicating a potential improvement in drought conditions. On
                        the other hand, in wet regions, there is a slight increase in drought risk,
                        suggesting a higher likelihood of experiencing drought events despite the
                        overall moisture levels. It is important to note that even though arid
                        regions still have a significant exposure to drought risk, the updated
                        methodology shows a relatively lower level of risk compared to the legacy
                        methodology. Therefore, while the risk is still present, it has been
                        somewhat reduced in the updated methodology.
                    </li>
                    <li>
                        <strong>Water Stress: </strong>Water stress risks showing minimal changes is
                        consistent with the limited changes observed in the underlying data and
                        methodology.
                    </li>
                    <li>
                        <strong>Pluvial Flooding: </strong>Pluvial flooding was not included in
                        legacy Climanomics®.
                    </li>
                </ul>
                <h2 className="spg-heading spg-heading--large spg-mt-md">Disclaimer</h2>
                <p className="spg-text spg-text-paragraph">
                    This content (including any information, data, analyses, opinions, ratings,
                    scores, and other statements) (“Content”) has been prepared solely for
                    information purposes and is owned by or licensed to S&P Global and/or its
                    affiliates (collectively, “S&P Global”).
                </p>
                <p className="spg-text spg-text-paragraph">
                    This Content may not be modified, reverse engineered, reproduced or distributed
                    in any form by any means without the prior written permission of S&P Global.
                </p>
                <p className="spg-text spg-text-paragraph">
                    You acquire absolutely no rights or licenses in or to this Content and any
                    related text, graphics, photographs, trademarks, logos, sounds, music, audio,
                    video, artwork, computer code, information, data and material therein, other
                    than the limited right to utilize this Content for your own personal, internal,
                    non-commercial purposes or as further provided herein.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Any unauthorized use, facilitation or encouragement of a third party’s
                    unauthorized use (including without limitation copy, distribution, transmission
                    or modification) of this Content or any related information is not permitted
                    without S&P Global’s prior consent and shall be deemed an infringement,
                    violation, breach or contravention of the rights of S&P Global or any applicable
                    third-party (including any copyright, trademark, patent, rights of privacy or
                    publicity or any other proprietary rights).
                </p>
                <p className="spg-text spg-text-paragraph">
                    A reference to a particular investment or security, a score, rating or any
                    observation concerning an investment or security that is part of this Content is
                    not a recommendation to buy, sell or hold such investment or security, does not
                    address the suitability of an investment or security and should not be relied on
                    as investment advice.
                </p>
                <p className="spg-text spg-text-paragraph">
                    S&P Global shall have no liability, duty or obligation for or in connection with
                    this Content, any other related information (including for any errors,
                    inaccuracies, omissions or delays in the data) and/or any actions taken in
                    reliance thereon. In no event shall S&P Global be liable for any special,
                    incidental, or consequential damages, arising out of the use of this Content
                    and/or any related information.
                </p>
                <p className="spg-text spg-text-paragraph">
                    The S&P and S&P Global logos are trademarks of S&P Global registered in many
                    jurisdictions worldwide. You shall not use any of S&P Global's trademarks, trade
                    names or service marks in any manner, and in no event in a manner accessible by
                    or available to any third party. You acknowledge that you have no ownership or
                    license rights in or to any of these names or marks.
                </p>
                <p className="spg-text spg-text-paragraph">
                    See additional Disclaimers at{' '}
                    <a className="spg-link" href="https://www.spglobal.com/en/terms-of-use">
                        https://www.spglobal.com/en/terms-of-use.
                    </a>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Copyright&copy; 2023 S&P Global Inc. All rights reserved.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">September 15, 2022 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Physical Risk Financial Impact Metrics for Public Companies now in Xpressfeed
                    and Snowflake
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are excited to announce that the new Physical Risk Scores and Financial
                    Impact packages on Xpressfeed and Snowflake are now live.
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    What do the new Physical Risk datasets include?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    The Physical Risk Scores and Financial Impact climate datasets provide asset and
                    company-level physical risk scores and financial impact to help financial and
                    non-financial organizations assess the impact of climate change on their
                    portfolios, operational assets and supply chains.
                </p>
                <p className="spg-text spg-text-paragraph">
                    With the integration of The Climate Service (TCS) into S&amp;P Global’s
                    Sustainable1 organization, we are now able to offer clients a harmonized product
                    that provides an assessment of the impact of physical hazards as percentage at
                    risk of an asset value, mapped to the company and the ultimate parent for risk
                    attribution. Financial Impact is assessed for over 20K companies and
                    approximately 870K assets.
                </p>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    <em>What are the key highlights of the release?</em>
                </h3>
                <p className="spg-text spg-text-paragraph">
                    With the enhancements made to the offerings, the datasets now include:
                </p>
                <ul className="spg-list">
                    <li>
                        Company and asset level physical risk scores reflecting exposure to climate
                        hazards across scenarios and time periods.
                    </li>
                    <li>
                        Company and asset level Financial Impact metrics reflecting the percentage
                        of asset value at risk due to changing climate hazard exposure across
                        scenarios and time periods.
                    </li>
                    <li>Eight physical risk hazards covered.</li>
                    <li>
                        Physical risk modeling using CMIP6, the latest generation of climate models
                        that employs the shared socioeconomic pathways (SSPs) in concert with the
                        Representative Concentration Pathways (RCP). The datasets now include four
                        SSPs with the addition of Medium-High SSP3-7.0.
                    </li>
                    <li>Eight physical risk hazards covered.</li>
                    <li>
                        Climate risk outputs for eight decades from the 2020s through the 2090s.
                    </li>
                </ul>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    How will the Harmonized Physical Risk data be packaged?
                </h3>
                <p className="spg-text spg-text-paragraph">
                    The Harmonized physical risk data will have two components: company-level data
                    and asset-level data. Each of these products will include both Risk Scores and
                    Financial Impact.
                </p>
                <p className="spg-text spg-text-paragraph">Specifically:</p>
                <table className="spg-table">
                    <thead>
                        <tr>
                            <th className="spg-p-md">Billable Product Name</th>
                            <th className="spg-p-md">Fulfillment components</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Physical Risk -Companies</td>
                            <td>
                                Physical Risk – Company Risk Scores
                                <Divider />
                                Physical Risk – Company Financial Impact
                            </td>
                        </tr>
                        <tr>
                            <td>Physical Risk - Assets</td>
                            <td>
                                Physical Risk – Asset Risk Scores
                                <Divider />
                                Physical Risk – Asset Financial Impact
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="spg-text spg-text-paragraph">
                    The combined Physical Risk Exposure Scores &amp; Financial Impact offering will
                    help investors understand the exposure of companies and associated assets to
                    physical and financial impacts under future climate change scenarios.
                </p>
                <p className="spg-text spg-text-paragraph">
                    For more information on this exciting new development, please contact your
                    Climanomics Relationship Manager or refer to{' '}
                    <a
                        className="spg-link"
                        href="https://www.marketplace.spglobal.com/en/datasets/physical-risk-%28148%29"
                    >
                        the Physical Risk Dataset tile in the S&amp;P Global Marketplace
                    </a>
                    .
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">August 8, 2022 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    New and Updated Asset Types Available
                </h1>
                <p className="spg-text spg-text-paragraph">
                    The 33 new asset types are now available. You may now assign these asset types
                    to your assets. View the list of the asset types{' '}
                    <Link to="/asset-type-updates-072722" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">July 27, 2022 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    New and Updated Asset Types
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are introducing 33 new asset types as part of our effort to provide more
                    relevant climate risk data for your holdings. The new asset types will be
                    available for assignment to assets by August 8. View the list of new asset types{' '}
                    <Link to="/asset-type-updates-072722" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">June 15, 2022 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Climate risk outputs for RCP 2.6 and 6.0 now available
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We have completed the climate risk modeling for RCP 2.6 and 6.0 and all client
                    assets in the S&amp;P Global Climanomics platform now have climate risk outputs
                    for both scenarios.
                </p>
                <ul className="spg-list">
                    <li>
                        <em>
                            <strong>
                                How do I see the climate risk outputs for the new scenarios?
                            </strong>
                        </em>
                        <p className="spg-text spg-text-paragraph">
                            Go to the “RCP scenario” dropdown on the upper right-hand corner of the
                            screen. You are now able to select one of four scenarios from the
                            previous two scenarios.
                        </p>
                    </li>
                    <li>
                        <em>
                            <strong>
                                Do the additional scenarios change existing risk values?
                            </strong>
                        </em>
                        <p className="spg-text spg-text-paragraph">
                            The additional scenarios have not changed risk outputs for RCP 4.5 and
                            8.5.
                        </p>
                    </li>
                    <li>
                        <em>
                            <strong>Background: What are RCP scenarios?</strong>
                        </em>
                        <p className="spg-text spg-text-paragraph">
                            The S&amp;P Global Climanomics platform incorporates emissions scenarios
                            based on the Representative Concentration Pathways (RCPs) from the
                            International Panel on Climate Change (IPCC). In the CMIP-5 generation
                            of climate models, which the S&amp;P Global Climanomics platform
                            currently employs, four RCPs are included (RCP8.5, RCP6, RCP4.5, and
                            RCP2.6), as shown in Figure 1 below.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            The scenarios are labeled by the total radiative forcing of the
                            emissions assumptions (cumulative measure of human emissions of GHGs
                            from all sources expressed in watts per square meter) in 2100. The RCPs
                            were chosen to represent a broad range of climate outcomes.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            <em>
                                Figure 1: Carbon dioxide emission pathways and RCPs From Fuss et
                                al., 2014 <sup>1</sup>.
                            </em>
                        </p>
                        <Image
                            src={imageCO2}
                            alt="Carbon dioxide emission pathways graphed against RCPs From Fussetal., 2014 paper."
                            width={491}
                        />
                        <p className="spg-text spg-text-paragraph">
                            NASA’s NEX-GDDP downscaled CMIP-5 data only included RCP8.5 and RCP4.5
                            scenarios <sup>2</sup>. As a result, to date, only these two scenarios
                            have been available in the Climanomics&#174; platform.
                            <b>
                                {' '}
                                This release adds two additional scenarios to the platform - RCP2.6
                                and RCP6.0 - through the use of an internally-developed
                                interpolation technique.
                            </b>
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            <b>RCP2.6</b> is the “Very Low Emissions” scenario, the lowest emissions
                            scenario among the four RCPs. This scenario assumes that emissions peak
                            early and then fall due to the active removal of greenhouse gases from
                            the atmosphere. It is estimated that end-of-century increases in global
                            mean surface temperature will be in the range of 0.9 to 2.3&deg;C.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            <b>RCP6.0</b> is the “Moderate-High Emissions” scenario, modeling an
                            emissions level that falls between RCP4.5 and RCP8.5 by the end of the
                            century. This scenario assumes a high greenhouse gas emission rate with
                            radiative forcing stabilization after 2100. It is estimated that
                            end-of-century increases in global mean surface temperature will be in
                            the range of 2.0 to 3.7&deg;C.
                        </p>
                    </li>
                    <li>
                        <em>
                            <strong>How are physical risks modeled for RCP 2.6 and 6.0?</strong>
                        </em>
                        <p className="spg-text spg-text-paragraph">
                            In order to provide analyses related to the two additional RCP
                            scenarios, RCP2.6 and RCP6.0, at the same level of resolution as the
                            other scenarios, S&amp;P Global Climanomics has developed and applied an
                            interpolation methodology to each of the 7 physical hazards: Temperature
                            Extremes, Drought, Water Stress, Wildfire, Coastal Flooding, Fluvial
                            Flooding, and Tropical Cyclone. The interpolation methodology is based
                            on the approximation that hazard values across the four scenarios should
                            relate to each other the same way as the model ensemble-mean global-mean
                            surface temperature (GMST) across the four scenarios. This method uses
                            the differences in estimated end-century GMST between all four scenarios
                            to interpolate decadal values for each localized hazard metric for
                            RCP2.6 and RCP6.0 from the corresponding decadal values for the fully
                            modeled RCP4.5 and RCP8.5 scenarios.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            In order to interpolate RCP2.6 and RCP6.0 hazard values, S&amp;P Global
                            Climanomics developed scaling factors for each scenario, motivated by
                            the observation that the geographic distributions of temperature are
                            similar in RCP45 and RCP85 projections, differing approximately by a
                            single scaling factor.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            S&amp;P Global Climanomics’ interpolation logic is applied as follows:
                        </p>
                        <ol className="spg-list">
                            <li>
                                From the 2020s–2040s, RCP2.6 and RCP6.0 are set equal to RCP4.5.{' '}
                                <em>
                                    (This is motivated by the observation that the GMST differs
                                    relatively little across the scenarios prior to the 2040s.)
                                </em>
                            </li>
                            <li>
                                From the 2050s–2090s, RCP2.6 hazard values will generally be equal
                                to modified RCP4.5 hazard values; this modification is derived from
                                the RCP2.6-specific scaling factor, which tapers towards the end of
                                the century.
                            </li>
                            <li>
                                From the 2050s–2090s, RCP6.0 hazard values will generally be equal
                                to modified RCP4.5 hazard values; this modification is derived from
                                the RCP6.0-specific scaling factor.
                            </li>
                            <li>
                                Additional rules were then applied, if necessary:
                                <ul className="spg-list">
                                    <li>
                                        Do not allow RCP2.6 to exceed RCP4.5 or RCP8.5 in any
                                        decade.
                                    </li>
                                    <li>Do not allow RCP6.0 to exceed RCP8.5 in any decade.</li>
                                    <li>
                                        If RCP4.5 &gt; RCP8.5, then set RCP2.6 and 6.0 equal to
                                        RCP8.5.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                        <p className="spg-text spg-text-paragraph">
                            When applied, the scaling approach means that the following situations
                            occur:
                        </p>
                        <ul>
                            <li>If RCP4.5 &lt; RCP8.5, RCP2.6 is less than or equal to RCP4.5.</li>
                            <li>
                                If RCP4.5 &lt; RCP8.5, RCP6.0 is between RCP4.5 and RCP8.5 or equal
                                to RCP4.5
                            </li>
                            <li>
                                If RCP4.5 &gt; RCP8.5, RCP2.6 = RCP6.0 = RCP8.5 and RCP4.5 &gt;
                                RCP2.6, RCP6.0, RCP8.5.
                            </li>
                        </ul>
                        <p className="spg-text spg-text-paragraph">
                            <b>Note: </b> In some locations, the interpolated values for Fluvial
                            Flooding under RCP2.6 may become negative in the 2090s. Such values
                            should be interpreted as 0 (zero) values.
                        </p>
                    </li>
                    <li>
                        <em>
                            <strong>How is Carbon Pricing modeled for RCP 2.6 and 6.0?</strong>
                        </em>
                        <p className="spg-text spg-text-paragraph">
                            The S&amp;P Global Climanomics platform utilizes carbon price
                            projections from the underlying data of the Shared Socioeconomic
                            Pathways (SSPs) models used by the IPCC. An SSP is one of a collection
                            of pathways that describe alternative futures of socio-economic
                            development. <sup>3</sup> <br />
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            For the platform’s RCP8.5 scenario, the system uses a carbon price model
                            based on the Shared Socioeconomic Pathways (SSP) scenario SSP3-60, which
                            assumes high challenges to both adaptation and mitigation. The price
                            varies across the five regions and through time, with the range of
                            values being from approximately $8/ton to approximately $82/ton by 2100.
                            Mid-century prices are approximately $29/ton.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            For the platform’s RCP4.5 scenario, the system uses a carbon price model
                            based on the SSP scenario SSP3-45. Prices range from approximately
                            $8/ton to approximately $440/ton by 2100. Mid-century prices are
                            approximately $65/ton.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            For RCP6.0, S&amp;P Global Climanomics uses interpolated carbon prices
                            based on scenarios SSP3-60 and SSP3-45. Prices range from approximately
                            $8/ton to $180/ton by 2100. Mid-century prices are around $50/ton.
                        </p>
                        <p className="spg-text spg-text-paragraph">
                            Lastly, the RCP2.6 scenario is based on carbon prices from SSP3-34.
                            Prices range from approximately $8/ton to approximately $880/ton by
                            2100. Mid-century prices are approximately $180/ton.
                        </p>
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">
                    <em>
                        <sup>1</sup> Fuss et al. “Betting on negative emissions.” Nature Climate
                        Change volume 4, pages 850–853 (2014){' '}
                    </em>
                    <br />
                    <em>
                        <sup>2</sup>{' '}
                        <a
                            className="spg-link"
                            target="_blank"
                            href="https://www.nccs.nasa.gov/services/data-collections/land-based-products/nex-gddp"
                            rel="noreferrer"
                        >
                            https://www.nccs.nasa.gov/services/data-collections/land-based-products/nex-gddp{' '}
                            <Icon icon={EXTERNAL_LINK} size={Size.SMALL} />
                        </a>
                    </em>
                    <br />
                    <em>
                        <sup>3</sup> More detail is available at{' '}
                        <a
                            className="spg-link"
                            target="_blank"
                            href="https://unfccc.int/sites/default/files/part1_iiasa_rogelj_ssp_poster.pdf"
                            rel="noreferrer"
                        >
                            https://unfccc.int/sites/default/files/part1_iiasa_rogelj_ssp_poster.pdf{' '}
                            <Icon icon={EXTERNAL_LINK} size={Size.SMALL} />
                        </a>
                        .{' '}
                    </em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">April 1, 2022 </h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    New and Updated Asset Types Available
                </h1>
                <p className="spg-text spg-text-paragraph">
                    The 28 new asset types and 5 updated ones are now available. You may now assign
                    these asset types to your assets. View the list of the asset types{' '}
                    <Link to="/asset-type-updates-031822" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">March 18, 2022</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    New and Updated Asset Types
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are introducing 28 new asset types as part of our effort to provide more
                    relevant climate risk data for your holdings. The new asset types will be
                    available for assignment to assets by March 31st. View the list of new asset
                    types{' '}
                    <Link to="/asset-type-updates-031822" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Moreover, we have updated 5 existing asset types as part of our continuing
                    effort to improve the accuracy of our models. View the list of updated asset
                    types{' '}
                    <Link to="/asset-type-updates-031822_2" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Some of these asset types have already been assigned to customer assets, but
                    this release will not change risk results for assets currently in S&amp;P Global
                    Climanomics. Risk results will only change if you assign any of the new asset
                    types to an existing asset.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">December 2, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updated Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Select Impact Functions have been updated, and the risk values of your holdings
                    may have changed.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    if you have questions regarding these changes.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">November 18, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updates to Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are updating Impact Functions on December 3rd and this update may lead to a
                    change in the risk values of your holdings. Impact functions are the building
                    blocks of our vulnerability model, and we are continuously upgrading these
                    models based on the latest peer-reviewed empirical evidence. The Climate Service
                    continues to adapt to evolving climate science and enhancements to our Impact
                    Functions are intended to increase confidence in our vulnerability modeling. You
                    may view the list of updates{' '}
                    <Link to="/impact-function-updates-120321" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>What action can I take now?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please{' '}
                    <a className="spg-link" href="https://www.climanomics.com/">
                        login to the S&amp;P Global Climanomics platform
                    </a>
                    , select "Export." You will have the option to download the Risk and Opportunity
                    results of your assets for either RCP 4.5 or RCP 8.5. We recommend that you
                    download both files by December 2nd, if you wish to preserve your current
                    climate risk analysis. Please ensure you are using Google Chrome browser and
                    that your company's firewall is not enabled. You should see the files appear in
                    your Downloads folder.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>When will my climate risk analysis be updated?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Updated climate risk analysis will start to display on the S&amp;P Global
                    Climanomics platform also by December 3rd.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    New and Updated Asset Types
                </h2>
                <p className="spg-text spg-text-paragraph">
                    We are introducing 12 new asset types as part of our effort to provide more
                    relevant climate risk data for your holdings. The new asset types can now be
                    assigned to agricultural as well as real estate assets. View the list of new
                    asset types{' '}
                    <Link to="/asset-type-updates-120321" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Moreover, we have updated 1 existing asset type as part of our continuing effort
                    to improve the accuracy of our models. View the asset type update{' '}
                    <Link to="/asset-type-updates-120321_2" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Some of these asset types have already been assigned to customer assets, but
                    this release will not change risk results for assets currently in S&amp;P Global
                    Climanomics. Risk results will only change if you assign any of the new asset
                    types to an existing asset.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">November 16, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Tropical Cyclone modeling coverage expanded to the Pacific
                </h1>
                <p className="spg-text spg-text-paragraph">
                    S&amp;P Global Climanomics has expanded its modeling of the Tropical Cyclone
                    (TC) hazard to the Northwest Pacific basin. S&amp;P Global Climanomics now
                    models regional TC hazards for the Northwest Pacific basin and the North
                    Atlantic basin. The expansion of our TC modeling means that assets located in
                    longitudes of 100.0°E to 180°E and latitudes of 0.0°N to 60°N are now assessed
                    for TC risk.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>How does this change affect your portfolio’s risk profile? </em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Assets that fall in the Northwest Pacific basin have been rescored to account
                    for Tropical Cyclone risk. If you have assets with Pacific locations, the risk
                    values of your portfolios may have changed. Your portfolio’s climate risk
                    profile is more comprehensive and reflective of overall risk.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>How does S&amp;P Global Climanomics model Tropical Cyclone? </em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    To estimate change in western North Atlantic TCs (longitudes 100.0°W to 65.0°W
                    degrees and latitudes 17.0°N to 46.0°N), S&amp;P Global Climanomics uses “NASHM”
                    (North Atlantic Stochastic Hurricane Model); for modeling Northwest Pacific
                    tropical cyclones (longitudes of 100.0°E to 180°E and latitudes of 0.0°N to
                    60°N), S&amp;P Global Climanomics uses “PaSHM” (Pacific Stochastic Hurricane
                    Model). NASHM and PaSHM are statistical-stochastic models trained on historical
                    TC data and indices of secular climate change and natural climate variability.
                    The models generate large synthetic TC event sets in various climate states, and
                    the event sets are used to estimate occurrence rates at high resolution.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Four key factors impacting regional tropical cyclone (TC) activity are:
                </p>
                <p className="spg-text spg-text-paragraph">
                    1. The number of TCs that form each year: Other factors being equal, higher
                    frequency of annual TC formation (e.g., the North Atlantic) increases the hazard
                    in coastal regions and lower frequency (e.g., the Northwest Pacific) decreases
                    the hazard.
                </p>
                <p className="spg-text spg-text-paragraph">
                    2. The region in the ocean where the TCs form: Some formation regions, such as
                    the Gulf of Mexico, result in TCs more likely to make landfall than other
                    regions, such as the eastern sub-tropical Atlantic.
                </p>
                <p className="spg-text spg-text-paragraph">
                    3. The intensity of the TCs: The hazard is highly sensitive to TC wind speed.
                </p>
                <p className="spg-text spg-text-paragraph">
                    4. The tracks that TCs follow: TCs are only hazardous to a coastal region if
                    they make landfall in or near the region.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    if you have questions regarding these changes.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">August 30, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updated Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Select Impact Functions have been updated, and your risk values may have
                    changed.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    if you have questions regarding these changes.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">August 17, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updates to Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are updating Impact Functions on August 28 and this update may lead to a
                    change in the risk values of your holdings. Impact functions are the building
                    blocks of our vulnerability model, and we are continuously upgrading these
                    models based on the latest peer-reviewed empirical evidence. The Climate Service
                    continues to adapt to evolving climate science and enhancements to our Impact
                    Functions are intended to increase confidence in our vulnerability modeling. You
                    may view the list of updates{' '}
                    <Link to="/impact-function-updates-081721" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>What action can I take now?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please{' '}
                    <a className="spg-link" href="https://www.climanomics.com/">
                        login to the S&amp;P Global Climanomics platform
                    </a>
                    , select "Export." You will have the option to download the Risk and Opportunity
                    results of your assets for either RCP 4.5 or RCP 8.5. We recommend that you
                    download both files by August 27th, if you wish to preserve your current climate
                    risk analysis. Please ensure you are using <b>Google Chrome</b> browser and that
                    your company's firewall is not enabled. You should see the files appear in your
                    Downloads folder.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>When will my climate risk analysis be updated?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Updated climate risk analysis will start to display on the S&amp;P Global
                    Climanomics platform also by August 28th.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    New Asset Types Available
                </h2>
                <p className="spg-text spg-text-paragraph">
                    We are introducing 17 new or updated asset types as part of our effort to
                    provide more relevant climate risk data for your holdings. The new asset types
                    can now be assigned to agricultural assets. View the list of new asset types{' '}
                    <Link to="/asset-type-updates-081721" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Some of these asset types have already been assigned to customer assets, but
                    this release will not change risk results for assets currently in S&amp;P Global
                    Climanomics. Risk results will only change if you assign any of the new asset
                    types to an existing asset.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">June 1, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updated Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Select Impact Functions have been updated, and your risk values may have
                    changed.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    if you have questions regarding these changes.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">May 18, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updates to Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are updating 36 Impact Functions on May 28th and this update may lead to a
                    change in the risk values of your holdings. Impact functions are the building
                    blocks of our vulnerability model, and we are continuously upgrading these
                    models based on the latest peer-reviewed empirical evidence. The Climate Service
                    continues to adapt to evolving climate science and enhancements to our Impact
                    Functions are intended to increase confidence in our vulnerability modeling. You
                    may view the list of updates{' '}
                    <Link to="/impact-function-updates-051821" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>What action can I take now?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please{' '}
                    <a className="spg-link" href="https://www.climanomics.com/">
                        login to the S&amp;P Global Climanomics platform
                    </a>
                    , select "Export." You will have the option to download the Risk and Opportunity
                    results of your assets for either RCP 4.5 or RCP 8.5. We recommend that you
                    download both files by May 27th, if you wish to preserve your current climate
                    risk analysis. Please ensure you are using <b>Google Chrome</b> browser and that
                    your company's firewall is not enabled. You should see the files appear in your
                    Downloads folder.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>When will my climate risk analysis be updated?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Updated climate risk analysis will start to display on the S&amp;P Global
                    Climanomics platform also by May 28th.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    New Asset Types Available
                </h2>
                <p className="spg-text spg-text-paragraph">
                    We are introducing 48 new or updated asset types as part of our effort to
                    provide more relevant climate risk data for your holdings. The new asset types
                    can now be assigned to agricultural assets. View the list of new asset types{' '}
                    <Link to="/asset-type-updates-051821" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    Some of these asset types have already been assigned to customer assets, but
                    this release will not change risk results for assets currently in S&amp;P Global
                    Climanomics. Risk results will only change if you assign any of the new asset
                    types to an existing asset.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">March 26, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updates to Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    We are updating 28 Impact Functions between April 2 and 4, and this update may
                    lead to a change in the risk values of your holdings. S&amp;P Global Climanomics
                    continues to adapt to evolving climate science and enhancements to our Impact
                    Functions are intended to increase confidence in our vulnerability modeling.
                    Impact functions are the building blocks of our vulnerability model, and we are
                    continuously upgrading these models based on the latest peer-reviewed empirical
                    evidence. You may view the list of updates{' '}
                    <Link to="/impact-function-updates-032621" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>What action can I take now?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please{' '}
                    <a className="spg-link" href="https://www.climanomics.com/">
                        login to the S&amp;P Global Climanomics platform
                    </a>
                    , select "Export." You will have the option to download the Risk and Opportunity
                    results of your assets for either RCP 4.5 or RCP 8.5. We recommend that you
                    download both files by April 1st, if you wish to preserve your current climate
                    risk analysis. Please ensure you are using Google Chrome browser and that your
                    company's firewall is not enabled. You should see the files appear in your
                    Downloads folder.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>When will my climate risk analysis be updated?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Updated climate risk analysis will start to display on the S&amp;P Global
                    Climanomics platform also by April 4th.
                </p>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    New Asset Types Available
                </h2>
                <p className="spg-text spg-text-paragraph">
                    We are introducing 32 new asset types as part of our effort to provide more
                    relevant climate risk data for your holdings. The new asset types can now be
                    assigned to assets in real estate, infrastructure and agriculture. View the list
                    of new asset types{' '}
                    <Link to="/asset-type-updates-031721" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    These asset types have not yet been assigned to any customer asset and this
                    release will not change risk results for assets currently in S&amp;P Global
                    Climanomics. Risk results will only change if you assign any of the new asset
                    types to an existing asset.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Please contact{' '}
                    <a className="spg-link" href="mailto:support@theclimateservice.com">
                        support@theclimateservice.com
                    </a>{' '}
                    for questions or clarifications.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">February 26, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updated Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Select Impact Functions have been updated, and your risk values may have
                    changed.
                </p>
                <Divider />
                <h3 className="spg-heading spg-heading--medium spg-mt-md">February 17, 2021</h3>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md">
                    Updates to Impact Functions
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Select Impact Functions will be updated on February 26, 2021 and your risk
                    values may change. Impact Functions are the building blocks of our vulnerability
                    model, and we are continuously upgrading these models based on the latest
                    peer-reviewed empirical evidence. View the list of updated impact functions{' '}
                    <Link to="/impact-function-updates-022621" className="spg-link">
                        here
                    </Link>
                    .
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>What action can I take now?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    We recommend that you download your current risk results by February 25, if you
                    wish to preserve your current climate risk analysis. Please ensure you are using
                    Google Chrome browser and that your company's firewall is not enabled. To
                    download, click on the "Export" button on your dashboard and export values for
                    RCP 8.5 and RCP 4.5. You should see the files appear in your Downloads folder.
                </p>
                <p className="spg-text spg-text-paragraph">
                    <em>When will my climate risk analysis be updated?</em>
                </p>
                <p className="spg-text spg-text-paragraph">
                    Updated risk analysis will be reflected in Climanomics starting February 26.
                </p>
            </div>
        </>
    );
}
