import React, { useState, useEffect } from 'react';
import MRPExportTable from './MRPExportTable';
import MRPExportForm from './MRPExportForm';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import { TableDataContextProvider, ParagraphLg } from '../components/shared';
import { DEFAULT_PAGE_DATA } from '../components/components.constants';
import { addDynamicStyles } from '../utils/generateReportUtils';
import { getReports } from '../services/exportService';

export default function MRPExportPage() {
    const [pageData, setPageData] = useState(DEFAULT_PAGE_DATA);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const getExportList = async (offset, limit, refreshData, searchData, sortData) => {
        let res = [];
        const reqData = {
            offset,
            limit,
            customerId: selectedCustomer.id,
            report_type: 'list_report',
        };
        if (searchData) reqData[`${searchData.searchtype}`] = searchData.searchText;
        if (sortData) {
            reqData.sort_field = sortData.sort_field;
            reqData.order_by = sortData.order_by;
        }
        if (refreshData) {
            res = refreshData;
            const result = addDynamicStyles(res);
            return result;
        }
        res = await getReports(reqData);
        const totalCount = res?.count;
        setCount(totalCount);
        const results = addDynamicStyles(res?.results);
        return results;
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                if (selectedCustomer?.id) {
                    const data = await getExportList(0, pageData.rowsPerPage);
                    setTableData(data);
                }
            })();
        } catch (e) {
            console.log('error occured in fetching export list: ', e);
        }
    }, [selectedCustomer]);

    const updateTableState = (data) => {
        // this we need to do because for a state with array, the component only re renders when we assign new array to the state,
        // instead of modifying the current array of the state.
        const updatedState = [...data];
        setTableData(updatedState);
    };

    return (
        <>
            <BreadcrumbTrail rootTitle="Multiple Return Period Modeling for Coastal Flood [BETA]" />
            <div className="spg-w-100 spg-p-md">
                <div className="spg-text spg-text-paragraph">
                <p>
                    The standard modeling of coastal flood hazard in Climanomics focuses on the projected frequency of the 1-in-100 year flood depth across scenarios and time periods. Coastal flood hazard can also be quantified as the flood depth associated with events at different return periods. Climanomics can now offer projected flood depths and associated damages (MAAL) across nine return periods (2, 5, 10, 20, 50, 100, 200, 500, and 1000 years), four scenarios and decades from the 2020s to the 2090s. Flood depths are expressed in meters.
                </p>
                <p>
                    The fractional damage associated with coastal flood at each return period is also computed, along with the mean fractional damage across all return periods and expressed as a percentage of the typical asset value for each asset type. The fractional damage (MAAL) at a given return period is calculated directly from asset type specific impact functions described in the impact function whitepapers available upon request, with fractional damages increasing with flood depth. Mean fractional damage is calculated by converting the range of return periods (2, 5, 10, 20, 50, 100, 200, 500, and 1000 years) to a probability density function and estimating the exceedance probability at each given value of flood depth. The mean fractional damage is calculated by combining the fractional damage at each flood depth with the probability of exceeding each depth.
                </p>
                <p>
                    This feature is in beta version and is not automatically available for all portfolios. To export your assets’ hazard and risk values for different return periods, please contact your Relationship Manager or <a href="mailto:Climanomics_Support@spglobal.com">Climanomics_Support@spglobal.com</a>.
                </p>
 
                </div>
                <MRPExportForm
                    selectedCustomer={selectedCustomer}
                    setSelectedCustomer={setSelectedCustomer}
                    getExportList={getExportList}
                    updateTableState={updateTableState}
                    pageData={pageData}
                />
                {/* <TableDataContextProvider exportTableData={tableData}>
                    <MRPExportTable
                        selectedCustomer={selectedCustomer}
                        tableData={tableData}
                        getExportList={getExportList}
                        updateTableState={updateTableState}
                        count={count}
                        pageData={pageData}
                        setPageData={setPageData}
                    />
                </TableDataContextProvider> */}
            </div>
        </>
    );
}
